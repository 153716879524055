<template>
    <v-btn icon @click="replyIt(comment)">
      <v-icon small  class="mx-1" :color="color">mdi-comment-text-outline</v-icon>
    </v-btn>
</template>

<script>
import eventBus from "../../plugins/eventBus";

export default {
  name: "reply-btn",
  data: () => ({
    color: '#868686'
  }),
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    comment: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    eventBus.$on("reset-reply-btn-color", () => {
      this.color = '#868686';
    });
  },
   watch: {
    user : {
      handler: function() {
        this.color = "#868686";
      }
    }
  },
  methods: {
    async replyIt(comment) {
      eventBus.$emit("reset-reply-btn-color", true);
      this.color = "blue";
      this.user.replyToCommentId = comment.comment.comment_id;
      setTimeout(function(){
        eventBus.$emit("enable-reply-comment", true); 
      }, 200) 
      
      
    }
  },

  beforeDestroy() {
    eventBus.$off('reset-reply-btn-color')
  }
}
</script>