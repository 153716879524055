<template>
    <v-btn icon @click="likeIt(comment)">
        <v-icon small class="mx-1" :color="color">mdi-thumb-up-outline</v-icon>
    </v-btn>
</template>

<script>

import { chatService } from "../../repositories/ChatService";

export default {
  name: "like-btn",
  data: () => ({
    color: '#868686',
    colorLiked : 'blue',
    colorNormal : '#868686',
    conversationId: null
  }),
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    comment: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.conversationId = this.user._id;
    this.color = this.comment.comment.liked ? this.colorLiked : this.colorNormal;
  },
  watch: {
    comment : {
      handler: async function (val) {
        this.color = val.comment.liked ? this.colorLiked : this.colorNormal;
      },
      deep: true,
    },
    user : {
      handler: async function (val) {
        this.conversationId = val._id;
      },
      deep: true,
    }
  },
  methods: {
    async likeIt(comment) {
      if(this.color == this.colorLiked) {
        try {
          let res = await chatService.unlikeComment(this.conversationId, comment.comment.comment_id);
          if (res) {
            this.color = this.colorNormal;
            this.$swal.fire({
              icon: "success",
              title: 'unlike OK',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            title: 'Có lỗi xảy ra',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        try {
          let res = await chatService.likeComment(this.conversationId, comment.comment.comment_id);
          if (res) {
            this.color = this.colorLiked;
            this.$swal.fire({
              icon: "success",
              title: 'OK',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            title: 'Có lỗi xảy ra',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    }
  }
}
</script>