<template>
  <v-navigation-drawer
    app
    clipped
    right
    width="303"
    class="customer-information"
    permanent
  >
    <b class="register-information">THÔNG TIN KHÁCH HÀNG</b>
    <v-divider height="20" class="customer-divider"></v-divider>
    <div id="drawer-right-customer" :class="customerHistories.length == 0 ? 'activeScrollRight' : ''" @scroll="handleScroll">
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <form class="info-class px-3">
          <div class="info-class-item">
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              rules="required"
              name="Họ tên"
            >
              <v-text-field
                placeholder="Họ & Tên*"
                :error-messages="errors"
                type="text"
                v-model="name"
                maxlength="155"
                dense
                outlined
                solo
                flat
                clearable
                autocomplete="off"
              />
            </validation-provider>
          </div>
          <div class="info-class-item">
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              :rules="{ isPhone: phone, required: phone }"
              name="Số điện thoại"
            >
              <v-text-field
                placeholder="Số điện thoại*"
                :error-messages="errors"
                type="text"
                v-model="phone"
                dense
                outlined
                solo
                flat
                clearable
              />
            </validation-provider>
          </div>
          <div class="info-class-item">
            <v-text-field
              placeholder="Năm sinh con"
              type="text"
              v-model="child_birth"
              dense
              outlined
              solo
              flat
              clearable
            />
          </div>
          <div class="info-class-item types">
            <div class="types-item">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required"
                name="Loại hình"
              >
                <v-select
                  :error-messages="errors"
                  class="pr-1"
                  placeholder="Loại hình*"
                  clearable
                  :items="user.page.learning_type"
                  item-text="name"
                  item-value="id"
                  v-model="type"
                  dense
                  solo
                  outlined
                  flat
                  append-icon="mdi-chevron-down"
                  id="request"
                  :return-object="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  no-data-text="Không có dữ liệu"
                  @change="changeProduct('type')"
                ></v-select>
              </validation-provider>
            </div>
            <div class="types-item">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required"
                name="Nhóm ngành"
              >
                <v-select
                  :error-messages="errors"
                  class="pl-1"
                  solo
                  placeholder="Nhóm ngành*"
                  clearable
                  :items="user.page.school_level"
                  item-text="name"
                  item-value="id"
                  v-model="school_level"
                  dense
                  outlined
                  flat
                  append-icon="mdi-chevron-down"
                  id="request"
                  :return-object="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  no-data-text="Không có dữ liệu"
                  @change="changeProduct('school_level')"
                ></v-select>
              </validation-provider>
            </div>
          </div>
          <div class="info-class-item">
            <validation-provider
              v-slot="{ errors }"
              class="mb-2"
              rules="required"
              name="Nhóm sản phẩm"
            >
              <v-select
                solo
                :error-messages="errors"
                placeholder="Nhóm sản phẩm*"
                clearable
                :items="product_group_filtered"
                item-text="name"
                item-value="name"
                v-model="product_group"
                dense
                outlined
                flat
                append-icon="mdi-chevron-down"
                id="request"
                :return-object="true"
                :menu-props="{ bottom: true, offsetY: true }"
                no-data-text="Không có dữ liệu"
                attach
                :disabled="disableProductGroup"
              ></v-select>
            </validation-provider>
          </div>
          <div class="info-class-item">
            <v-text-field
              solo
              placeholder="Môn học"
              clearable
              v-model="subject"
              dense
              outlined
              flat
              id="request"
              autocomplete="off"
            ></v-text-field>
          </div>
          <div class="info-class-item">
            <v-autocomplete
                solo
                placeholder="Nhân viên sale"
                clearable
                :items="userSale"
                item-text="fullName"
                item-value="username"
                v-model="sale"
                dense
                outlined
                flat
                append-icon="mdi-chevron-down"
                id="request"
                :menu-props="{ bottom: true, offsetY: true }"
                no-data-text="Không có dữ liệu"
                attach>
            </v-autocomplete>
          </div>
          <div class="info-class-item content">
            <v-textarea
              name=""
              id="lessoncontent"
              placeholder="Ghi chú"
              v-model="note"
              outlined
              flat
              clearable
              height="84"
            ></v-textarea>
          </div>
          <div class="button">
            <v-btn
              :disabled="disabledUpdate"
              class="button-save save white--text"
              color="#FF6609"
              block
              @click.prevent="handleSubmit(updateCustomerInformation)"
              >CẬP NHẬT</v-btn
            >
          </div>
        </form>
      </validation-observer>
      <div class="history-update" v-if="customerHistories.length > 0">
        <div class="title-history-update">Lịch sử</div>

        <table
          class="mt-2 pt-5 info-history-update"
          v-for="(customer, index) in customerHistories"
          :key="index"
        >
          <tr>
            <td class="customer-histories">Ngày cập nhật:</td>
            <td class="customer-date">
              {{ formatDate(customer.updatedAt) }}
            </td>
          </tr>
          <tr>
            <td class="customer-histories">Tên:</td>
            <td>{{ customer.name }}</td>
          </tr>
          <tr>
            <td class="customer-histories">SĐT:</td>
            <td>{{ customer.phone }}</td>
          </tr>
          <tr>
            <td class="customer-histories">Năm sinh con:</td>
            <td class="customer-color">{{ customer.child_birth }}</td>
          </tr>
          <tr>
            <td class="customer-histories">Nhóm sản phẩm:</td>
            <td>{{ customer.product_group_name }}</td>
          </tr>
          <tr>
            <td class="customer-histories">Môn học:</td>
            <td>{{ customer.subject_name }}</td>
          </tr>
           <tr>
            <td class="customer-histories">NVTP:</td>
            <td>{{ customer.e_name }}</td>
          </tr>
        </table>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { chatService } from "../../repositories/ChatService";
import moment from "moment";
export default {
  name: "vh-navigation-drawler-right",
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    customerHistories: {
      type: Array,
      default: () => [],
    },
    userSale: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    drawer: null,
    name: "",
    phone: "",
    child_birth: "",
    note: "",
    type: null,
    school_level: null,
    product_group: null,
    sale: null,
    subject: "",
    type_name: null,
    school_level_name: null,
    product_group_id: null,
    disabledUpdate: false,
    disableProductGroup: true,
    product_group_filtered: [],
  }),
  async created() {
    this.name = this.user.customer.name;

    if (this.user.customer.erp_phones && this.user.customer.erp_phones.length) {
      const arrSort = this.user.customer.erp_phones.sort(this.compareMoment);
      this.phone = arrSort[0].phone;
      this.$store.dispatch("SET_PHONEUSER", this.phone);
    } else if (this.user.customer.phones && this.user.customer.phones.length) {
      const arrSort = this.user.customer.phones.sort(this.compareMoment);
      this.phone = arrSort[0].phone;
      this.$store.dispatch("SET_PHONEUSER", this.phone);
    }
    if (this.customerHistories.length > 0) {
      this.product_group_filtered = [];
      this.disableProductGroup = false;
      this.child_birth = this.customerHistories[0].child_birth;
      this.type = this.customerHistories[0].type_id;
      this.type_name = this.customerHistories[0].type_name;
      this.school_level = this.customerHistories[0].school_level_id;
      this.school_level_name = this.customerHistories[0].school_level_name;
      this.subject = this.customerHistories[0].subject_name;
      this.sale = this.customerHistories[0].sale
      if (this.user.page.product_group.length > 0) {
        for (var x in this.user.page.product_group) {
          if (
            this.user.page.product_group[x].id.indexOf(
              this.customerHistories[0].type_id +
                "|" +
                this.customerHistories[0].school_level_id +
                "|"
            ) != -1
          ) {
            this.product_group_filtered.push({
              id: this.user.page.product_group[x].id,
              name: this.user.page.product_group[x].name,
            });
          }
        }
      }
      this.product_group = this.customerHistories[0].product_group_name;
      this.product_group_id = this.customerHistories[0].product_group_id;
    }
  },
  watch: {
    user: {
      handler: function async(val) {
        this.name = val.customer.name;
        this.phone = "";
        if (val.customer.erp_phones && val.customer.erp_phones.length) {
          const arrSort = val.customer.erp_phones.sort(this.compareMoment);
          this.phone = arrSort[0].phone;
          this.$store.dispatch("SET_PHONEUSER", this.phone);
        } else if (val.customer.phones && val.customer.phones.length) {
          const arrSort = val.customer.phones.sort(this.compareMoment);
          this.phone = arrSort[0].phone;
          this.$store.dispatch("SET_PHONEUSER", this.phone);
        }
        if (this.user.page.product_group.length > 0 && this.customerHistories.length > 0) {
          for (var x in this.user.page.product_group) {
            if (
              this.user.page.product_group[x].id.indexOf(
                this.customerHistories[0].type_id +
                  "|" +
                  this.customerHistories[0].school_level_id +
                  "|"
              ) != -1
            ) {
              this.product_group_filtered.push({
                id: this.user.page.product_group[x].id,
                name: this.user.page.product_group[x].name,
              });
            }
          }
        }
      },
    },
    customerHistories: {
      handler: function (val) {
        this.product_group_filtered = [];
        if (val.length > 0) {
          this.disableProductGroup = false;
          this.child_birth = val[0].child_birth;
          this.type = val[0].type_id;
          this.school_level = val[0].school_level_id;
          this.product_group = val[0].product_group_id;
          this.subject = val[0].subject_name;
          this.sale = val[0].sale;
          this.type_name = this.customerHistories[0].type_name;
          this.school_level_name = this.customerHistories[0].school_level_name;
          if (this.user.page.product_group.length > 0) {
            for (var x in this.user.page.product_group) {
              if (
                this.user.page.product_group[x].id.indexOf(
                  val[0].type_id + "|" + val[0].school_level_id + "|"
                ) != -1
              ) {
                this.product_group_filtered.push({
                  id: this.user.page.product_group[x].id,
                  name: this.user.page.product_group[x].name,
                });
              }
            }
          }
          this.product_group = val[0].product_group_name;
          this.product_group_id = val[0].product_group_id;
        } else {
          this.child_birth = "";
          this.note = "";
          this.type = null;
          this.school_level = null;
          this.product_group = null;
          this.subject = "";
          this.sale = null
          this.$refs.validator.reset();
        }
      },
      deep: true,
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("HH:mm DD-MM-YYYY");
    },
    changeProduct() {
      this.product_group = null;
      this.product_group_filtered = [];
      // if(val == 'type' && this.school_level) {
      //    this.school_level = {
      //     id : this.school_level
      //   }
      // }
      // if(val == 'school_level' && this.type) {
      //   this.type = {
      //     id : this.type
      //   }
      // }
      if (
        typeof this.type == "number" &&
        this.school_level &&
        this.school_level.id
      ) {
        this.disableProductGroup = false;
        if (this.user.page.product_group.length > 0) {
          for (var x in this.user.page.product_group) {
            if (
              this.user.page.product_group[x].id.indexOf(
                this.type + "|" + this.school_level.id + "|"
              ) != -1
            ) {
              this.product_group_filtered.push({
                id: this.user.page.product_group[x].id,
                name: this.user.page.product_group[x].name,
              });
            }
          }
        }
      }
      if (typeof this.school_level == "number" && this.type && this.type.id) {
        this.disableProductGroup = false;
        if (this.user.page.product_group.length > 0) {
          for (var y in this.user.page.product_group) {
            if (
              this.user.page.product_group[y].id.indexOf(
                this.type.id + "|" + this.school_level + "|"
              ) != -1
            ) {
              this.product_group_filtered.push({
                id: this.user.page.product_group[y].id,
                name: this.user.page.product_group[y].name,
              });
            }
          }
        }
      }
      if (
        this.type &&
        this.type.id &&
        this.school_level &&
        this.school_level.id
      ) {
        this.disableProductGroup = false;
        if (this.user.page.product_group.length > 0) {
          for (var z in this.user.page.product_group) {
            if (
              this.user.page.product_group[z].id.indexOf(
                this.type.id + "|" + this.school_level.id + "|"
              ) != -1
            ) {
              this.product_group_filtered.push({
                id: this.user.page.product_group[z].id,
                name: this.user.page.product_group[z].name,
              });
            }
          }
        }
      }
    },
    async updateCustomerInformation() {
      this.disabledUpdate = true;
      let params = {
        name: this.name,
        phone: this.phone,
        child_birth: this.child_birth,
        note: this.note,
        subject_name: this.subject,
        conversation_id: this.user._id
      };
      if (!this.child_birth) {
        params.child_birth = "";
      }
      if (!this.subject) {
        params.subject_name = "";
      }
      if(this.sale) {
        params.sale = this.sale.toString()
      }
      if(!this.sale) {
        delete params.sale
      }
      if (!this.note) {
        params.note = "";
      }
      if (this.type && typeof this.type == "object") {
        params.type_id = this.type.id;
        params.type_name = this.type.name;
      }
      if (typeof this.type == "number") {
        params.type_id = this.type;
        params.type_name = this.type_name;
      }
      if (typeof this.school_level == "number") {
        params.school_level_id = this.school_level;
        params.school_level_name = this.school_level_name;
      }
      if (this.school_level && typeof this.school_level == "object") {
        params.school_level_id = this.school_level.id;
        params.school_level_name = this.school_level.name;
      }
      if (typeof this.product_group == "object") {
        // chi lay dung id cua nhom san pham vi luc no dang co gia tri o dang loai_hinh | nhom_nganh | nhom_san_pham
        var pgid = this.product_group.id.split("|").slice(-1).pop();
        params.product_group_id = pgid;
        params.product_group_name = this.product_group.name;
      }
      if (typeof this.product_group == "string") {
        params.product_group_id = this.product_group_id;
        params.product_group_name = this.product_group;
      }
      console.log(this.user, '542')
      const res = await chatService.postCustomer(this.user.psid, params);
      this.disabledUpdate = false;
      if (res.success) {
        this.$emit("getCustomerHistories", this.user.psid);
        this.$swal.fire({
          icon: "success",
          title: "Cập nhật thành công!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs.validator.reset();
        this.disableProductGroup = true;
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Cập nhật không thành công!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    handleScroll(event) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 2
      ) {
        this.$emit("scrollCustomer", this.user.psid);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.customer-information {
  .customer-divider {
    background: #55a6dc;
    border-width: revert;
    color: #55a6dc;
  }
  .info-class {
    margin-top: 20px;
    .info-class-item {
      margin-top: -5px !important;
      &.types {
        display: flex;
        width: 100%;
        .types-item {
          width: 50%;
        }
      }
    }
  }
  .button {
    margin-top: -8px;
  }

  .history-update {
    padding: 12px;
    position: relative;
    margin-top: 4px;
    .title-history-update {
      font-size: 12px;
      color: #6b778c;
      width: 76px;
      height: 26px;
      background: #ffffff;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      text-align: center;
      margin-top: 12px;
    }
    .info-history-update {
      border-top: 1px solid #ddd;
      text-align: left;
      font-size: 12px;
      color: #6b778c;
      justify-content: space-around;
      width: 100%;
      .customer-histories {
        width: 45%;
      }
      .customer-color {
        color: red;
      }
      .customer-date {
        color: black;
        font-weight: 700;
      }
    }
  }
}
</style>
