<template>
  <v-main id="main-box" v-if="user" class="overflow-hidden pb-2 custom-top">
    <v-app-bar class="header-main" color="white" height="68">
      <v-list-item>
        <template>
          <v-list-item-avatar width="60" height="60">
            <v-img
              width="60"
              :alt="`${user.customer.name} avatar`"
              :src="user.customer.avatar_url || imgDefault"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title style="display: flex; flex-wrap: wrap"
              >{{ user.customer.name }}
              <div v-if="phone">
                <v-icon small class="pl-2" color="green lighten-1"
                  >mdi-phone</v-icon
                >
                <span style="color: Blue; font-size: 13px">
                  {{ $store.state.phoneUser ?  $store.state.phoneUser : '11111111' }}
                </span>
              </div>
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="user.e_id"
              style="font-size: 12px"
              class="mt-1"
            >
              Đã xem bởi {{ user.e_name }} vào lúc
              {{ formatDate(user.first_read) }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>
      </v-list-item>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="blue" dark v-bind="attrs" v-on="on"> mdi-home </v-icon>
        </template>
        <span>{{ $store.state.namePage ? $store.state.namePage : "" }}</span>
      </v-tooltip>
    </v-app-bar>
    <BoxMessages v-if="user.messages" :user="user" />
    <BoxComments v-if="user.comments" :user="user" />
  </v-main>
  <v-main v-else class="deep-orange lighten-5 " style="background: #f1f1f1 !important;"
    ><div class="before-select">
      <b>select a chat to start messaging</b>
    </div>
  </v-main>
</template>

<script>
import moment from "moment";
import BoxMessages from "./BoxMessages.vue";
import BoxComments from "./BoxComments.vue";

export default {
  name: "vh-main",
  data: () => ({
    scroll: null,
    active: false,
    imgDefault: "https://cdn-cf.vuihoc.vn/default/default_avatar.jpg",
  }),
  components: {
    BoxMessages,
    BoxComments,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    phone: {
      type: String,
      default: () => null,
    },
  },
  watch: {
    user : {
      handler : function(val) {
        if (val.customer.erp_phones && val.customer.erp_phones.length) {
          const arrSort = val.customer.erp_phones.sort(this.compareMoment);
          this.phone = arrSort[0].phone;
          this.$store.dispatch("SET_PHONEUSER", this.phone);
        } else if (val.customer.phones && val.customer.phones.length) {
          const arrSort = val.customer.phones.sort(this.compareMoment);
          this.phone = arrSort[0].phone;
          this.$store.dispatch("SET_PHONEUSER", this.phone);
        }
      }
    }
  },
  created() {
    if (this.user && this.user.customer.erp_phones && this.user.customer.erp_phones.length) {
      const arrSort = this.user.customer.erp_phones.sort(this.compareMoment);
      this.phone = arrSort[0].phone;
      this.$store.dispatch("SET_PHONEUSER", this.phone);
    } else if (this.user && this.user.customer.phones && this.user.customer.phones.length) {
      const arrSort = this.user.customer.phones.sort(this.compareMoment);
      this.phone = arrSort[0].phone;
      this.$store.dispatch("SET_PHONEUSER", this.phone);
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
#main-box {
  background: #fff8f3;
  &.custom-top {
    padding: 10px 303px 92px 400px !important;
  }
  .v-list-item--three-line {
    min-height: 0px !important;
  }
  .v-sheet:not(.v-sheet--outlined) {
    box-shadow: none ;
  }
  .v-text-field__details {
    display: none;
  }
  .v-progress-circular {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  .header-main {
    height: 100px;
    margin-top: 0px;
    transform: translateY(0px);
    left: 400px !important;
    z-index: 9999;
    width: calc(100% - 703px);
    top: 72px;
    position: fixed;
    box-shadow: rgb(0 0 0 / 6%) 0px 4px 6px 0px !important;
  }
  #chat-main {
    overflow-y: auto;
    max-height: calc(100vh - 310px);

    &.activeHeight {
      max-height: calc(100vh - 388px);
    }
    //  max-height: calc(100vh);
    background: #fff8f3;
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: 0px 4px 4px 0px #00000029 inset;
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a0a0a0;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #bebebe;
    }
  }
  .img-chat {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
  }
}
.before-select {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  b {
    border: 1px solid #efecec;
    border-radius: 20px;
    padding: 3px 14px;
    color: #545353;
    background: #EEF0FF;
  }
}

.page {
  display: flex;
  margin-top: 10px;
  align-items: center;
  min-height: 32px;
  width: 100%;
  justify-content: right;
  .page-mess {
    position: relative;
    margin-right: 50px;
    width: max-content;
    max-width: 405px;
    align-items: center;
    background: #172b4d;
    display: flex;
    border-radius: 14px;
    /* min-height: 39px; */
    padding: 7px 18px;
    &.mess-image {
      background: transparent;
    }

    .v-avatar {
      position: absolute;
      right: -39px;
      top: 20px;
    }
  }
}

.user {
  display: flex;
  margin-top: 10px;
  align-items: center;
  min-height: 32px;
  width: 100%;
  justify-content: left;

  .user-mess {
    position: relative;
    margin-left: 50px;
    background: #ffffff;

    width: max-content;
    max-width: 405px;
    align-items: center;
    display: flex;
    border-radius: 14px;
    /* min-height: 39px; */
    padding: 7px 18px;
    &.mess-image {
      background: transparent;
    }
    .v-avatar {
      position: absolute;
      left: -48px;
      top: 20px;
    }
  }
}
</style>
