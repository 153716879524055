var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"box-comment",class:_vm.active ? 'activeHeight' : '',attrs:{"id":"cmt-main"},on:{"scroll":_vm.handleScroll}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px","max-height":"100% !important"},model:{value:(_vm.msgDialog),callback:function ($$v) {_vm.msgDialog=$$v},expression:"msgDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center title-box-message"},[_c('span',{staticClass:"text-h5"},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"#3A559F"}},[_vm._v("mdi-facebook-messenger")]),_vm._v("Tin nhắn mới đến "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.sendToName ? _vm.sendToName : _vm.user.customer.name))])],1),_c('v-btn',{staticClass:"float-right",attrs:{"icon":""},on:{"click":function($event){_vm.msgDialog = false}}},[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"comment-dialog-messages"},[(_vm.userMessage)?_c('DialogMessages',{attrs:{"user":_vm.userMessage}}):_vm._e()],1),_c('div',{staticClass:"action-comment-box-messages"},[_c('SendMessageBox',{attrs:{"currentUser":_vm.user,"user":_vm.userMessage,"currentComment":_vm.currentComment},on:{"getMessage":_vm.getMessage,"getImage":_vm.getImage}})],1)]),_c('v-card-actions',[_c('v-spacer')],1)],1)],1),_c('div',{staticClass:"post-wrapper"},[_c('div',{staticClass:"post"},[_vm._v(" "+_vm._s(_vm.user.post.message)+" ")]),(_vm.user.post.attachments.length)?_c('div',{staticClass:"post-image"},_vm._l((_vm.user.post.attachments),function(img,index){return _c('div',{key:index,staticClass:"mr-1"},[(img.type == 'photo')?_c('v-dialog',{attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":img.payload.url,"width":"100","height":"100"}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"img-dialog"},[_c('img',{attrs:{"src":img.payload.url,"alt":""}})])]):_vm._e(),(img.type == 'video_inline')?_c('video',{attrs:{"src":img.payload.url,"controls":""}}):_vm._e()],1)}),0):_vm._e(),_c('div',{staticClass:"go-to-permalink float-right"},[_c('a',{attrs:{"href":'https://facebook.com/' + _vm.user.post.id,"target":"_blank"}},[_vm._v("Xem bài gốc")])]),_c('div',{staticClass:"clearfix"})]),_c('div',_vm._l((_vm.user.comments),function(comment,index){return _c('div',{key:index,class:comment.comment.is_echo ? 'page' : 'user',attrs:{"color":"rgba(0,0,0,0)","flat":""}},[_c('div',{class:comment.comment.is_echo ? 'page-mess-new' : 'user-mess-new',style:({
          background:
            comment.comment.attachments.length > 0 ? 'transparent' : '',
          padding: comment.comment.attachments.length > 0 ? '0px 0px' : '',
        })},[(!comment.comment.is_echo)?_c('v-avatar',{staticClass:"mt-n5 ml-2",attrs:{"size":"30","elevation":"10"}},[_c('img',{attrs:{"alt":((_vm.user.name) + " avatar"),"src":_vm.user.customer.avatar_url || _vm.imgDefault}})]):_vm._e(),_c('div',{staticClass:"name-time d-flex"},[_c('div',{staticClass:"name-cmt"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.customer ? _vm.user.customer.name : "Ẩn danh")+" ")]),_c('div',{staticClass:"time-cmt my-auto"},[_vm._v(_vm._s(_vm.formatDateCustom(comment.comment.ts)))])]),_c('div',{staticStyle:{"font-size":"15px","white-space":"pre-line"},style:({
            color: comment.comment.is_echo ? '#ffffff' : '#172B4D',
          })},[_vm._v(" "+_vm._s(comment.comment.message)+" "),(_vm.checkValidatePhone(comment.comment.message).btnPhone)?_c('div',{staticClass:"btn-copy-phone"},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"x-small":"","depressed":"","color":"#FD7C23","value":_vm.checkValidatePhone(comment.comment.message).btnPhone[
                  _vm.checkValidatePhone(comment.comment.message).btnPhone
                    .length - 1
                ]},on:{"click":function($event){_vm.copyPhone(
                  _vm.checkValidatePhone(comment.comment.message).btnPhone[
                    _vm.checkValidatePhone(comment.comment.message).btnPhone
                      .length - 1
                  ]
                )}}},[_vm._v("Lưu SĐT ")])],1):_vm._e()]),(comment.comment.attachments.length > 0)?_c('div',{staticClass:"img-chat"},_vm._l((comment.comment.attachments),function(img,indexImg){return _c('div',{key:indexImg,staticClass:"mr-1"},[(
                img.type == 'image' || img.type == '' || img.type == 'photo'
              )?_c('v-dialog',{attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"comment-photo",attrs:{"src":img.payload.url,"width":"100","height":"100"}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"img-dialog"},[_c('img',{attrs:{"src":img.payload.url,"alt":""}})])]):_vm._e(),(img.type == 'video')?_c('video',{staticClass:"comment-video",attrs:{"src":img.payload.url,"controls":""}}):_vm._e()],1)}),0):_vm._e(),(!comment.comment.is_echo)?_c('div',[_c('LikeBtn',{attrs:{"user":_vm.user,"comment":comment}}),_c('HideBtn',{attrs:{"user":_vm.user,"comment":comment}}),_c('ReplyBtn',{attrs:{"user":_vm.user,"comment":comment}}),_c('v-btn',{attrs:{"icon":"","href":'https://facebook.com/' + comment.comment.comment_id,"target":"_blank"}},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"#3A559F"}},[_vm._v("mdi-facebook")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"#868686"}},[_vm._v("mdi-trash-can-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openMessageBoxByComment(comment)}}},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"blue"}},[_vm._v("mdi-facebook-messenger")])],1)],1):_vm._e()],1),_vm._l((_vm.repliesComments(index)),function(item2,index2){return _c('div',{key:index2,staticClass:"user-reply",attrs:{"color":"rgba(0,0,0,0)","flat":""}},[(item2.attachments)?_c('div',{staticClass:"user-mess-new",style:({ background: item2.is_echo ? 'black' : 'white' })},[(item2.is_echo)?_c('v-avatar',{staticClass:"mt-n5 ml-2",attrs:{"size":"30","elevation":"10"}},[_c('img',{attrs:{"alt":((_vm.user.name) + " avatar"),"src":_vm.user.page.avatar || _vm.imgDefault}})]):_vm._e(),(!item2.is_echo)?_c('v-avatar',{staticClass:"mt-n5 ml-2",attrs:{"size":"30","elevation":"10"}},[_c('img',{attrs:{"alt":((_vm.user.name) + " avatar"),"src":_vm.user.customer.avatar_url != null
                  ? _vm.user.customer.avatar_url
                  : _vm.imgDefault}})]):_vm._e(),(item2.sender)?_c('v-avatar',{staticClass:"mt-n5 ml-2",attrs:{"size":"30","elevation":"10"}},[_c('img',{attrs:{"alt":((item2.sender.name) + " avatar"),"src":item2.sender.avatar_url != null
                  ? item2.sender.avatar_url
                  : _vm.imgDefault}})]):_vm._e(),_c('div',{staticStyle:{"font-size":"15px","white-space":"pre-line"},style:({ color: item2.is_echo ? 'white' : 'black' })},[_c('div',{staticClass:"name-time d-flex"},[_c('div',{staticClass:"name-cmt"},[_vm._v(" "+_vm._s(item2.sender ? item2.sender.name : "")+" ")]),_c('div',{staticClass:"time-cmt my-auto"},[_vm._v(_vm._s(_vm.formatDateCustom(item2.ts)))])]),_vm._v(" "+_vm._s(item2.message)+" "),_c('div',{staticClass:"statusMessCmt"},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(
                  item2.statusMess &&
                  item2.statusMess == _vm.constant.SEND_MESS_PENDDING
                ),expression:"\n                  item2.statusMess &&\n                  item2.statusMess == constant.SEND_MESS_PENDDING\n                "}],staticClass:"mt-1",attrs:{"size":15,"indeterminate":"","color":"primary"}}),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(
                  item2.statusMess &&
                  item2.statusMess == _vm.constant.SEND_MESS_ERROR
                ),expression:"\n                  item2.statusMess &&\n                  item2.statusMess == constant.SEND_MESS_ERROR\n                "}],attrs:{"color":"red","small":""}},[_vm._v("mdi-alert-circle-outline")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(
                  item2.statusMess &&
                  item2.statusMess == _vm.constant.SEND_MESS_SUCCESS
                ),expression:"\n                  item2.statusMess &&\n                  item2.statusMess == constant.SEND_MESS_SUCCESS\n                "}],attrs:{"color":"blue","small":""}},[_vm._v("mdi-check-all")])],1),(_vm.checkValidatePhone(item2.message).btnPhone)?_c('div',{staticClass:"btn-copy-phone"},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"x-small":"","depressed":"","color":"#FD7C23","value":_vm.checkValidatePhone(item2.message).btnPhone[
                    _vm.checkValidatePhone(item2.message).btnPhone.length - 1
                  ]},on:{"click":function($event){_vm.copyPhone(
                    _vm.checkValidatePhone(item2.message).btnPhone[
                      _vm.checkValidatePhone(item2.message).btnPhone.length - 1
                    ]
                  )}}},[_vm._v("Lưu SĐT ")])],1):_vm._e()]),(item2.attachments.length > 0)?_c('div',{staticClass:"img-chat"},_vm._l((item2.attachments),function(img,index3){return _c('div',{key:index3,staticClass:"mr-1"},[(
                  img.type == 'image' || img.type == '' || img.type == 'photo'
                )?_c('v-dialog',{attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"comment-photo",attrs:{"src":img.payload.url,"width":"100","height":"100"}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"img-dialog"},[_c('img',{attrs:{"src":img.payload.url,"alt":""}})])]):_vm._e(),(img.type == 'video')?_c('video',{staticClass:"comment-video",attrs:{"src":img.payload.url,"controls":""}}):_vm._e()],1)}),0):_vm._e(),(!item2.is_echo)?_c('div',[_c('LikeBtnReply',{attrs:{"user":_vm.user,"comment":item2}}),_c('HideBtnReply',{attrs:{"user":_vm.user,"comment":item2}}),_c('ReplyBtnReply',{attrs:{"user":_vm.user,"comment":item2}}),_c('v-btn',{attrs:{"icon":"","href":'https://facebook.com/' + item2.comment_id,"target":"_blank"}},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"#3A559F"}},[_vm._v("mdi-facebook")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"#868686"}},[_vm._v("mdi-trash-can-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openMessageBoxByComment(item2, item2.sender)}}},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"blue"}},[_vm._v("mdi-facebook-messenger")])],1)],1):_vm._e()],1):_vm._e()])})],2)}),0),_c('div',{attrs:{"id":"scroll"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }