<template>
  <v-app id="inspire">
    <!-- <SystemBar /> -->

    <AppBar />

    <NavigationDrawlerLeft
      :userSale="userSale"
      :users="conversations"
      :labels="labels"
      @scrollEndCov="scrollEndCov"
      @getCovSearch="getCovSearch"
      @searchConv="searchConv"
    />

    <NavigationDrawlerRight
      v-if="user"
      :user="user"
      :userSale="userSale"
      :customerHistories="customerHistories"
      @getCustomerHistories="getCustomerHistories"
      @scrollCustomer="scrollCustomer"
    />

    <div class="box-main">
      <Main :user="user" :phone="customerPhone" />

      <FooterMessage
        v-if="user && user.messages && user.messages.length"
        :user="user"
        :labels="labels"
        :covLabels="covLabels"
        @getMessage="getMessage"
        @getImage="getImage"
        @getConversationAddLabel="getConversationAddLabel"
        @repliedConversation="replied_conversation"
        :latestTs="latestTs"
      />
      <FooterComment
        :user="user"
        :labels="labels"
        :covLabels="covLabels"
        @getCmt="getCmt"
        @getConversationAddLabel="getConversationAddLabel"
        v-if="user && user.comments && user.comments.length"
      />
    </div>
      <v-dialog
              content-class="modal-shift"
              v-model="isDialog"
              persistent
              max-width="700px"
              max-height="100% !important"
      >
          <v-card>
              <div v-if="messagePageShift === null" style="padding: 30px;">
                  <div style="font-size: 17px !important; font-weight: 600;">
                      Xác nhận bắt đầu ca
                  </div>
                  <div style="font-size: 13px; font-style: italic;font-weight: 300;color: #333333">
                      (Vui lòng mở ca để bắt đầu chat)
                  </div>
                  <div class="page-shift__content my-7">
                      <div  style="grid-column: span 2 / span 2;">
                          <div class="page-shift__content__label">Ngày trực</div>
                          <div class="page-shift__content__input">{{ detailPageShift && detailPageShift.date ? detailPageShift.date : '' }}</div>
                      </div>
                      <div  style="grid-column: span 2 / span 2;">
                          <div class="page-shift__content__label">Giờ bắt đầu ca</div>
                          <div class="page-shift__content__input">{{ currentTime }}</div>
                      </div>
                      <div style="grid-column: span 3 / span 3;">
                          <div class="page-shift__content__label">Người trực</div>
                          <div class="page-shift__content__input">{{ detailPageShift && detailPageShift.user ?  detailPageShift.user.lastName + " "+  detailPageShift.user.firstName : '' }}</div>
                      </div>
                  </div>
                  <div class="page-shift__content" style="font-size: 14px;">
                      <div  style="grid-column: span 2 / span 2;">
                          Các page trực:
                      </div>
                      <div v-if="detailPageShift && detailPageShift.page.length > 0"  style="grid-column: span 5 / span 5;">
                          <div v-for="(page, pageIndex) in detailPageShift.page" :key="pageIndex" class="d-flex align-center mb-1" style="column-gap: 10px;">
                              <div style="width: 4px;height: 4px; border-radius: 100%; background-color: black"></div>
                              <div>{{ page.page_name }} <span style="font-style: italic;font-weight: 300">( {{ page.conv_unread_count }} hội thoại chờ )</span></div>
                          </div>
                      </div>
                  </div>
                  <div class="d-flex align-center mt-8" style="justify-content: end">
                      <v-btn @click="handleConfirm" depressed color="#7367F0" style="color: white !important;text-transform: unset !important;width: 130px;">
                          Xác nhận
                      </v-btn>
                  </div>
              </div>
              <template v-else>
                  <div style="padding-top: 30px; text-align: center;padding-bottom: 15px;">
                      <div v-html="messagePageShift"></div>
                  </div>
                  <div style="display: flex;justify-content: center;padding-bottom: 30px;">
                      <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M43.1851 7.83104C33.5124 -2.01169 17.6738 -2.14972 7.83107 7.52295C-2.01167 17.1956 -2.14969 33.0342 7.52298 42.877C17.1957 52.7197 33.0342 52.8577 42.877 43.185C52.7197 33.5124 52.8577 17.6738 43.1851 7.83104ZM32.3324 36.0218L25.3232 28.8894L18.1908 35.8986L14.6862 32.3324L21.8186 25.3232L14.8094 18.1908L18.3756 14.6862L25.3848 21.8186L32.5172 14.8094L36.0218 18.3756L28.8894 25.3848L35.8986 32.5172L32.3324 36.0218Z" fill="#FF0000"/>
                      </svg>
                  </div>
              </template>
          </v-card>
      </v-dialog>
      <v-dialog
              content-class="modal-shift"
              v-model="isDialogClose"
              persistent
              max-width="600px"
              max-height="100% !important"
      >
          <v-card>
              <div style="padding: 30px;">
                  <div style="font-size: 17px !important; font-weight: 600;">
                      Xác nhận kết thúc ca
                  </div>
                  <div class="page-shift__content my-7" style=" grid-template-columns: repeat(8, minmax(0, 1fr)); !important;">
                      <div  style="grid-column: span 3 / span 3;">
                          <div class="page-shift__content__label">Ngày trực</div>
                          <div class="page-shift__content__input">{{ detailPageShift && detailPageShift.date ? detailPageShift.date : '' }}</div>
                      </div>
                      <div  style="grid-column: span 5 / span 5;">
                          <div class="page-shift__content__label">Người trực</div>
                          <div class="page-shift__content__input">{{ detailPageShift && detailPageShift.user ?  detailPageShift.user.lastName + " "+  detailPageShift.user.firstName : '' }}</div>
                      </div>
                  </div>
                  <div class="page-shift__content my-7" style=" grid-template-columns: repeat(8, minmax(0, 1fr)); !important;">
                      <div  style="grid-column: span 3 / span 3;">
                          <div class="page-shift__content__label">Ca trực</div>
                          <div class="page-shift__content__input">{{ detailPageShift && detailPageShift.nameShift ? detailPageShift.nameShift : '' }}</div>
                      </div>
                      <div  style="grid-column: span 2 / span 2;">
                          <div class="page-shift__content__label">Giờ bắt đầu ca</div>
                          <div class="page-shift__content__input">{{ detailPageShift && detailPageShift.checkinAt ? formatHour(detailPageShift.checkinAt) : '' }}</div>
                      </div>
                      <div  style="grid-column: span 1 / span 1;display: flex; align-items: center;justify-content: center;padding-top: 25px">
                          <div style="background: #A2A2A2;width: 20px; height: 2px;">

                          </div>
                      </div>
                      <div style="grid-column: span 2 / span 2;">
                          <div class="page-shift__content__label">Giờ chốt ca</div>
                          <div class="page-shift__content__input">{{ currentTime }}</div>
                      </div>
                  </div>
                  <div class="d-flex align-center mt-8" style="justify-content: end;column-gap: 15px;">
                      <v-btn @click="isDialogClose = false" depressed color="#F2F2F2" style="color: #828282 !important;text-transform: unset !important;width: 130px;">
                          Hủy
                      </v-btn>
                      <v-btn @click="handleConfirmClose" depressed color="#7367F0" style="color: white !important;text-transform: unset !important;width: 130px;">
                          Xác nhận
                      </v-btn>
                  </div>
              </div>
          </v-card>
      </v-dialog>
  </v-app>
</template>

<script>
import AppBar from "../baseComponents/AppBar.vue";
import NavigationDrawlerLeft from "../components/Authchat/NavigationDrawlerLeft.vue";
import NavigationDrawlerRight from "../components/Authchat/NavigationDrawlerRight.vue";
import Main from "../components/Authchat/Main.vue";
import FooterMessage from "../components/Authchat/FooterMessage.vue";
import FooterComment from "../components/Authchat/FooterComment.vue";
import { chatService } from "../repositories/ChatService";
import { pageService } from "../repositories/PageService";
import ErpService from "../repositories/ErpService";
import eventBus from "../plugins/eventBus";
import TokenService from "../helpers/token";
import { constant } from "../helpers/constant";
import _ from "lodash";
import moment from "moment";
import userShiftService from "@/repositories/UserShiftService";

export default {
  name: "chat",
  data: () => ({
    drawer: null,
    msg: "some text here",
    users: [],
    user: null,
    id: null,
    unread_count: 0,
    labels: [],
    covLabels: [],
    startDate: null,
    endDate: null,
    notFound: false,
    customerHistories: [],
    totalUnRead: null,
    pageCustomer: 1,
    pDate: null,
    psid: null,
    customerPhone: null,
    ts: null,
    disableScrollTop: true,
    nameAndLabels: null,
    typeCov: null,
    pageIds: null,
    dateCov: null,
    tsCov: null,
    params: {
      conv_type: "all",
    },
    arrPages: [],
    indexSend: null,
    userSale: [],
    latestTs: null,
    detailPageShift: null,
    messagePageShift: null,
    isDialog: false,
    isDialogClose: false,
    currentTime: ''
  }),
  components: {
    AppBar,
    NavigationDrawlerLeft,
    NavigationDrawlerRight,
    Main,
    FooterMessage,
    FooterComment,
  },
  computed: {
    conversations() {
      return this.users;
    },
  },
  created() {
    if(this.checkPermission('bitu-chat-advise-divide-shift_read')) {
      this.checkUserShift();
      this.updateTime();
      setInterval(() => {
        this.updateTime();
      }, 1000);
      eventBus.$on("open-close-shift", () => {
        this.isDialogClose = true
      });
    }
    // end shift
    this.getListPages();
    this.getConversationCreated();
    this.getLabels();
    this.getUserSale();
    eventBus.$on("pick-user", async (user) => {
      this.$store.dispatch("SET_NAMEPAGE", user.page.name);
      if (user.unread_count > 0) {
        this.$store.dispatch(
          "SET_TOTALUNREAD",
          this.$store.state.totalUnRead - 1
        );
        user.unread_count = 0;
      }
      this.covLabels = [];
      if (this.id) {
        let data = {
          conversation_id: this.id,
        };
        this.unsubscribed_conversation(data);
      }
      this.customerPhone = null;
      if (user.customer.erp_phones && user.customer.erp_phones.length) {
        const arrSort = user.customer.erp_phones.sort(this.compareMoment);
        this.customerPhone = arrSort[0].phone;
      } else if (user.customer.phones && user.customer.phones.length) {
        const arrSort = user.customer.phones.sort(this.compareMoment);
        this.customerPhone = arrSort[0].phone;
      }
      this.id = user._id;
      this.psid = user.psid;
      this.pageCustomer = 1;
      let type =
        user && user.type == constant.MESSAGE
          ? constant.MESSAGE
          : constant.COMMENT;
      this.getConvMessage(user._id, type);
      this.getCustomerHistories(user.psid);
      this.subscribe_conversation(user._id);
      eventBus.$emit("scrollEnd");
      eventBus.$emit("scrollEndCMT");
    });
    eventBus.$on("scrollTop", () => {
      this.disableScrollTop = false;
      this.ts = this.user.messages[0].message.ts;
      let id = this.user._id;
      const params = {
        p_date: this.formatDate(this.user.messages[0].message.ts),
        limit: 20,
      };
      if (this.disableScrollTop) {
        return;
      }
      chatService
        .getConvMessage(id, params)
        .then((res) => {
          this.disableScrollTop = true;
          res.data.messages.reverse();
          if (this.user) {
            let messages = res.data.messages.concat(this.user.messages);
            if (this.ts == messages[0].message.ts) {
              eventBus.$emit("stopScrollTop");
            }
            this.user.messages = messages;
          } else {
            this.user = res.data;
          }
          res.data.customer.labels.map((s) => {
            this.covLabels.push(`${s.label_id}`);
          });
          eventBus.$emit("disableScrollTop");
        })
        .catch((err) => {
          this.disableScrollTop = true;
          this.$swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    });
  },
  sockets: {
    new_conversation: function (data) {
      this.updateConversations(data);
    },
    update_conversation: function (data) {
      if (this.id === data.id && !data.replied) {
         let type =
        data && data.type == constant.MESSAGE
          ? constant.MESSAGE
          : constant.COMMENT;
        this.getConvMessage(data.id, type);
      }
      this.updateConversations(data);
    },
    psid_label_updated: function (data) {
      this.updateLabels(data);
    },
    customer_updated: function (data) {
      this.updateCustomer(data);
      if (data.length && this.psid === data[0].psid) {
        this.customerPhone = data[0].phone;
      }
    },
    shift_label_opened: function (data) {
        if(data.userShiftId === this.detailPageShift._id) {
            this.detailPageShift.checkinAt = data.checkinAt
            this.$store.dispatch("SET_PAGE_SHIFT", this.detailPageShift)
            this.isDialog = false
        }
    },
    shift_label_closed: function (data) {
        if(data.userShiftId === this.detailPageShift._id) {
            this.detailPageShift.checkoutAt = data.checkoutAt
            this.$store.dispatch("SET_PAGE_SHIFT", this.detailPageShift)
            this.isDialogClose = false
            setTimeout(() => {
                this.checkUserShift()
            }, 3000)
        }
    },
    shift_label_to_end_time: function (data) {
        if(data.userShiftId === this.detailPageShift._id) {
            this.isDialogClose = true
        }
    },
    shift_label_to_auto_end_time: function (data) {
        if(data.userShiftId === this.detailPageShift._id) {
            this.detailPageShift.checkoutAt = data.checkoutAt
            this.$store.dispatch("SET_PAGE_SHIFT", this.detailPageShift)
            this.isDialogClose = false
        }
    },
  },
    methods: {
    formatHour(time) {
        return moment(time).format('HH:mm');
    },
    async handleConfirmClose() {
        try {
            const response = await userShiftService.closingShift(this.detailPageShift._id)
            if(response.data.length === 0) {
                this.$swal({
                    title: response.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2500,
                    toast: true,
                    position: 'top-right',
                });
            }else {
                this.isDialogClose = false
                this.$swal({
                    title: 'Đã chốt ca làm việc!',
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2500,
                    toast: true,
                    position: 'top-right',
                });
            }
        }catch (e) {
            console.log(e)
        }
    },
    async handleConfirm() {
        try {
            const response = await userShiftService.openShift(this.detailPageShift._id)
            console.log(response)
            if(response.data.length === 0) {
                this.$swal({
                    title: response.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2500,
                    toast: true,
                    position: 'top-right',
                });
            }else {
                this.isDialog = false
                this.$swal({
                    title: 'Ca đã được mở!',
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2500,
                    toast: true,
                    position: 'top-right',
                });
            }
        }catch (e) {
            console.log(e)
        }
    },
    updateTime() {
        this.currentTime = moment().format('HH:mm');
    },
    async checkUserShift() {
        try{
            const response = await userShiftService.checkUserShiftApi()
            if(response.data.length === 0) {
                this.isDialog = true
                this.messagePageShift = response.message
            }else {
                this.detailPageShift = response.data
                this.$store.dispatch("SET_PAGE_SHIFT", this.detailPageShift)
                if(this.detailPageShift && this.detailPageShift.checkinAt) {
                    this.isDialog = false
                }else {
                    this.isDialog = true
                }
                this.messagePageShift = null
            }
        }catch (e) {
            console.log(e)
        }
    },
    getUnreadConversation() {
      const params = {
        conv_type: "all",
      };
      // Get chatData
      chatService
          .getUnreadConversation(params)
          .then((res) => {
            this.$store.dispatch("SET_TOTALUNREAD", res.data.unread_count);
          })
          .catch((err) => {
            this.$swal.fire({
              icon: "error",
              title: err.message,
              showConfirmButton: false,
              timer: 1500,
            });
          });
    },
    subscribe_conversation: function (id) {
      let data = {
        conversation_id: id,
        e_id: TokenService.getId(),
        e_name: TokenService.getUserName(),
      };
      this.$socket.emit("subscribe_conversation", data);
    },
    replied_conversation: function () {
        let data = {
            conversation_id: this.id,
            e_id: TokenService.getId(),
            e_name: TokenService.getUserName(),
        };
        this.$socket.emit("replied_conversation", data);
    },
    unsubscribed_conversation: function (id) {
      this.$socket.emit("unsubscribed_conversation", id);
    },
    getConversationCreated() {
      // Get chatData
      chatService
        .getConversation(this.params)
        .then((res) => {
          this.users = res.data;
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
      this.getUnreadConversation()
    },
    async getConvMessage(id, type = constant.MESSAGE) {
      const params = {
        limit: type === constant.MESSAGE ? 20 : 9999,
      };
      const res = await chatService.getConvMessage(id, params);

      if (res.data.messages) {
        this.latestTs = res.data.messages[0].message.ts;
        res.data.messages.reverse();
      }
      if (res.data.comments) {
        res.data.comments.reverse();
      }
      this.user = res.data;
      this.covLabels = [];
      res.data.customer.labels.map((s) => {
        this.covLabels.push(`${s.label_id}`);
      });
    },
    getLabels() {
      chatService
        .getLabels()
        .then((res) => {
          this.labels = res.data;
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    getMessage(msg, status) {
      if (status == constant.SEND_MESS_PENDDING) {
        let appendMsg = {
          message: {
            is_echo: true,
            statusMess: status,
            text: msg,
            attachments: [],
            ts: moment().format("YYYY-MM-DDThh:mm:ss.SSS")
          },
        };
        this.latestTs = appendMsg.message.ts;
        this.user.messages.push(appendMsg);
      } else {
        const index = this.user.messages.findIndex(
          (s) =>
            s.message.statusMess == constant.SEND_MESS_PENDDING &&
            s.message.text == msg
        );
        if (index > -1) {
          this.user.messages[index].message.statusMess = status;
        }
      }
    },
    getImage(image, status) {
      if (status == constant.SEND_MESS_PENDDING) {
        let appendMsg = {
          message: {
            attachments: [
              {
                payload: { url: image },
                type: "image",
              },
            ],
            is_echo: true,
            statusMess: status,
          },
        };
        this.user.messages.push(appendMsg);
      } else {
        const index = this.user.messages.findIndex(
          (s) =>
            s.message.statusMess == constant.SEND_MESS_PENDDING &&
            s.message.attachments[0].payload.url == image
        );
        if (index > -1) {
          this.user.messages[index].message.statusMess = status;
        }
      }
    },
    getCmt(id, text, image, status) {
      if (status == constant.SEND_MESS_PENDDING) {
        let index = this.user.comments.findIndex(
          (s) => s.comment.comment_id == id
        );
        this.user.comments.map((s, idx) => {
          const indexRep = s.replies.findIndex((s) => s.comment_id == id);
          if (indexRep > -1) {
            index = idx;
          }
        });

        if (index > -1) {
          this.indexSend = index;
          let appendMsg = {
            comment_id: id,
            statusMess: constant.SEND_MESS_PENDDING,
            message: text,
            is_echo: true,
            attachments: [],
          };
          if (image) {
            appendMsg.attachments = [
              {
                type: "image",
                payload: {
                  url: image,
                },
              },
            ];
          }
          if (this.user.comments[index].replies) {
            this.user.comments[index].replies.push(appendMsg);
          } else {
            this.user.comments[index].replies = [appendMsg];
          }
        }
      } else {
        if (
          this.user.comments[this.indexSend].replies &&
          this.user.comments[this.indexSend].replies[
            this.user.comments[this.indexSend].replies.length - 1
          ].statusMess == constant.SEND_MESS_PENDDING
        ) {
          this.user.comments[this.indexSend].replies[
            this.user.comments[this.indexSend].replies.length - 1
          ].statusMess = status;
        }
      }
      setTimeout(function(){
        eventBus.$emit("scrollEndCMT");
      }, 100)

    },
    scrollEndCov() {
      if (this.users.length == 0) {
        return;
      }
      this.tsCov = this.users[this.users.length - 1].ts;
      this.params = {
        p_date: this.formatDate(this.users[this.users.length - 1].ts),
        ...this.nameAndLabels,
      };
      if (this.dateCov) {
        this.params.start_date = this.formatDate(this.dateCov.start);
        this.params.end_date = this.formatDate(this.dateCov.end);
      }
      if (this.typeCov) {
        this.params.conv_type = this.typeCov;
      }
      if (this.pageIds) {
        this.params.page_ids = this.pageIds;
      }
      chatService
        .getConversation(this.params)
        .then((res) => {
          let cov = this.users.concat(res.data);
          eventBus.$emit("loading");
          if (this.tsCov == cov[cov.length - 1].ts) {
            return;
          }
          this.users = cov;
          this.pDate = this.formatDate(cov[cov.length - 1].ts);
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          eventBus.$emit("loading");
        });
    },
    getCovSearch(val, date) {
      this.dateCov = date;
      this.params = {
        ...this.nameAndLabels
      };
      if (_.isArray(val)) {
        this.pageIds = val;
        this.params.page_ids = val;
        this.params.sale = val;
        this.typeCov = null;
      } else {
        this.typeCov = val;
        this.params.conv_type = val;
        this.pageIds = null;
      }

      if (date) {
        this.params.start_date = this.formatDate(date.start);
        this.params.end_date = this.formatDate(date.end);
      }

      // delete this.params.keyword;
        console.log(this.params , 'this.params')
      chatService
        .getConversation(this.params)
        .then((res) => {
          eventBus.$emit("loading");
          this.users = res.data;
        })
        .catch((err) => {
          eventBus.$emit("loading");
          this.$swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    searchConv(val) {
      this.params = {
        ...val,
      };
      if (this.dateCov) {
        this.params.start_date = this.formatDate(this.dateCov.start);
        this.params.end_date = this.formatDate(this.dateCov.end);
      }
      if (this.typeCov) {
        this.params.conv_type = this.typeCov;
      }
      if (this.pageIds) {
        this.params.page_ids = this.pageIds;
      }
      this.nameAndLabels = val;
      chatService
        .getConversation(this.params)
        .then((res) => {
          eventBus.$emit("loading");
          this.users = res.data;
        })
        .catch((err) => {
          eventBus.$emit("loading");
          this.$swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    async getCustomerHistories(psid) {
      const params = {
        page: this.pageCustomer,
        psid,
      };
      const res = await chatService.getCustomerHistories(params);
      this.customerHistories = res.data;
      if(this.customerHistories.length > 0) {
        this.$store.dispatch("SET_PHONEUSER", this.customerHistories[0].phone);
      }
    },
    async scrollCustomer(psid) {
      this.pageCustomer = this.pageCustomer + 1;
      const params = {
        page: this.pageCustomer,
        psid,
      };
      const res = await chatService.getCustomerHistories(params);
      if (res.data.length > 0) {
        let historiesCustomer = this.customerHistories.concat(res.data);
        this.customerHistories = historiesCustomer;
      } else {
        this.pageCustomer = this.pageCustomer - 1;
      }
    },
    getConversationAddLabel() {
      // Ussing data in socket
      // getConversationAddLabel(id) {
      //   this.getConversationCreated();
      //   let type =
      //     this.user && this.user.type == constant.MESSAGE
      //       ? constant.MESSAGE
      //       : constant.COMMENT;
      //   this.getConvMessage(id, type);
    },
    updateConversations(itemUser) {
      let tmpItem = null;
      this.users.forEach((item, index) => {
        if (itemUser._id === item._id && itemUser.post_id === item.post_id) {
          tmpItem = item;
          tmpItem.last_content = itemUser.last_content;
          tmpItem.unread_count = itemUser.unread_count;
          tmpItem.replied = itemUser.replied;
          tmpItem.ts = itemUser.ts
          this.users.splice(index, 1);
          this.users.unshift(tmpItem);
        }
      });
      // Nếu user lâu chưa nhắn tin cho page thì cần check cả page_id được assigns cho user để làm case này.
      if (!tmpItem && this.arrPages.includes(itemUser.page_id)) {
        if (this.params.p_date) {
          delete this.params.p_date;
        }
        chatService
          .getConversation(this.params)
          .then((res) => {
            res.data.forEach((item) => {
              if (
                itemUser._id === item._id &&
                itemUser.post_id === item.post_id
              ) {
                tmpItem = item;
                tmpItem.last_content = itemUser.last_content;
                tmpItem.unread_count = itemUser.unread_count;
                this.users.unshift(tmpItem);
              }
            });
          })
          .catch((err) => {
            this.$swal.fire({
              icon: "error",
              title: err.message,
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },

    updateLabels(label) {
      this.users.forEach((item) => {
        if (item.psid === label.psid) {
          item.customer.labels = label.labels;
        }
      });
    },
    updateCustomer(arrCustomer) {
      const customer = arrCustomer[0];
      this.users.forEach((item, index) => {
        if (customer.psid === item.psid) {
          this.users[index].customer.erp_phones.push(customer.phone);
        }
      });
    },
    async getListPages() {
      let params = {
        limit: 500,
        assign: true,
      };
      const res = await pageService.getListPages(params);
      this.arrPages = res.data.map((item) => item.page_id);
    },
    async getUserSale() {
      const res = await ErpService.getUserSale();
      this.userSale = res.data.data
    }
  },
  beforeDestroy() {
    // removing eventBus listener
    this.unsubscribed_conversation(this.id);
    eventBus.$off("pick-user");
    eventBus.$off("scrollTop");
  },
};
</script>
<style scoped lang="scss">
.box-main {
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 100%;
  bottom: 0;
}

</style>

<style lang="scss">
.v-overlay--active {
    bottom: 0 !important;
    height: calc(100% - 72px) !important;
    top: unset !important;
}
.page-shift {
  &__content {
    display: grid;
    column-gap: 12px;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    &__label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 3px;
    }
    &__input {
      background-color: #F4F4F4;
      border-radius: 4px;
      padding: 8px 15px;
      font-size: 14px;
    }
  }
}
.modal-shift { }
</style>