<template>
  <v-sheet
    @scroll="handleScroll"
    id="chat-main"
    class="bg-main"
    :class="active ? 'activeHeight' : ''"
  >
    <div>
      <div
        v-for="(message, index) in user.messages"
        :key="index"
        color="rgba(0,0,0,0)"
        flat
        :class="message.message.is_echo ? 'page' : 'user'"
        :id="
          user.messages.length > 20 && index == 20
            ? 'scrollMes'
            : user.messages.length <= 20 && index == user.messages.length - 1
            ? 'scrollMes'
            : ''
        "
      >
        <div
          :style="{
            background:
              message.message.attachments.length > 0 ? 'transparent' : '',
            padding: message.message.attachments.length > 0 ? '0px 0px' : '',
          }"
          :class="message.message.is_echo ? 'page-mess-new' : 'user-mess-new'"
        >
          <div class="d-flex">
            <v-avatar
              v-if="!message.message.is_echo"
              class="mt-n5 ml-2"
              size="30"
              elevation="10"
            >
              <img
                :alt="`${user.name} avatar`"
                :src="user.customer.avatar_url || imgDefault"
              />
            </v-avatar>
            <div
              class="box-msg"
              :style="{
                color: message.message.is_echo ? '#ffffff' : '#172B4D',
              }"
            >
              {{ message.message.text }}
              <div class="statusMess">
                <v-progress-circular
                  v-if="
                    message.message.is_echo &&
                    message.message.statusMess == constant.SEND_MESS_PENDDING
                  "
                  class="mt-1"
                  :size="15"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-icon
                  color="red"
                  small
                  v-if="
                    message.message.is_echo &&
                    message.message.statusMess == constant.SEND_MESS_ERROR
                  "
                  >mdi-alert-circle-outline</v-icon
                >
                <v-icon
                  color="blue"
                  small
                  v-if="
                    message.message.is_echo &&
                    message.message.statusMess == constant.SEND_MESS_SUCCESS
                  "
                  >mdi-check-all</v-icon
                >
              </div>
              <div
                class="btn-copy-phone"
                v-if="
                  message.message.phone_number_detected &&
                  message.message.phone_number_detected !== 'false' &&
                  !message.message.is_echo
                "
              >
                <v-btn
                  x-small
                  style="color: white"
                  depressed
                  color="#FD7C23"
                  @click="copyPhone(message.message.phone_number_detected)"
                  :value="message.message.phone_number_detected"
                  >Lưu SĐT
                </v-btn>
              </div>
            </div>

            <div class="img-chat" v-if="message.message.attachments.length > 0">
              <div
                v-for="(img, index) in message.message.attachments"
                :key="index"
              >
                <v-dialog width="auto" v-if="img.type == 'image'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      v-bind="attrs"
                      v-on="on"
                      :src="img.payload.url"
                      width="100"
                      height="100"
                    ></v-img>
                  </template>
                  <div class="img-dialog">
                    <img :src="img.payload.url" alt="" />
                  </div>
                </v-dialog>
              </div>
              <div
                v-for="(video, index) in message.message.attachments"
                :key="index"
              >
                <video
                  v-if="video.type === 'video'"
                  width="320"
                  height="176"
                  controls="controls"
                >
                  <source :src="video.payload.url" type="video/mp4" />
                </video>
              </div>
              <div
                v-for="(audio, index) in message.message.attachments"
                :key="index"
              >
                <audio controls v-if="audio.type === 'audio'">
                  <source :src="audio.payload.url" type="audio/mpeg" />
                </audio>
              </div>
            </div>
            <v-avatar
              v-if="message.message.is_echo"
              class="mt-n5 ml-2"
              size="30"
              elevation="10"
            >
              <img :src="user.page.avatar || imgDefault" />
            </v-avatar>
          </div>
          <div class="time-msg" :style="{paddingRight: message.message.attachments.length > 0 ? '8px' : ''}">{{ formatDateCustom(message.message.ts) }}</div>
        </div>
        
      </div>
    </div>
  </v-sheet>
</template>

<script>
import eventBus from "../../plugins/eventBus";
import moment from "moment";
import { constant } from "../../helpers/constant";
export default {
  name: "box-messages",
  data: () => ({
    stopScroll: true,
    scroll: null,
    active: false,
    imgDefault: "https://cdn-cf.vuihoc.vn/default/default_avatar.jpg",
    constant,
  }),
  components: {},
  props: {
    user: {
      type: [Array, Object],
      default: () => {},
    },
  },
  created() {
    eventBus.$on("file-img", (val) => {
      if (val == null) {
        this.active = false;
      } else {
        this.active = true;
      }
    });
    eventBus.$on("scrollEnd", () => {
      this.stopScroll = true;
      this.scrollToEnd();
    });
    eventBus.$on("stopScrollTop", () => {
      this.stopScroll = false;
    });
  },
  methods: {
    handleScroll(event) {
      if (this.user.messages && this.user.messages.length < 20) {
        return;
      } else {
        if (this.stopScroll) {
          if (event.target.scrollTop === 0) {
            eventBus.$emit("scrollTop");
          }
        }
      }
    },
    scrollToEnd: function () {
      if (document.getElementById("scrollMes")) {
        document.getElementById("scrollMes").scrollIntoView();
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    copyPhone(phoneMsg) {
      navigator.clipboard.writeText(phoneMsg);
    },
  },
  mounted() {
    this.scrollToEnd();
  },
  updated() {
    this.scrollToEnd();
  },
  beforeDestroy() {
    eventBus.$off("file-img");
    eventBus.$off("scrollEnd");
    eventBus.$off("stopScrollTop");
  },
};
</script>
<style lang="scss">
#main-box {
  background: #EEF0F1 !important;
  height: calc(100vh - 223px);
  .v-list-item--three-line {
    min-height: 0px !important;
  }
  .v-sheet:not(.v-sheet--outlined) {
    box-shadow: none !important;
  }
  .v-text-field__details {
    display: none;
  }
  .v-progress-circular {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  #chat-main {
    &.bg-main {
      background: #EEF0F1 !important;
    }
    overflow-y: auto;
    max-height: calc(100vh - 310px);

    &.activeHeight {
      max-height: calc(100vh - 388px);
    }
    //  max-height: calc(100vh);
    background: #fff8f3;
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: 0px 4px 4px 0px #00000029 inset;
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a0a0a0;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #bebebe;
    }
  }
  .img-chat {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
  }
  .statusMess {
    position: absolute;
    left: -30px;
    top: 4px;
  }
}
.before-select {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  b {
    border: 1px solid #efecec;
    border-radius: 20px;
    padding: 3px 14px;
    color: #545353;
    background: #fbd8d8;
  }
}

.page {
  display: flex;
  margin-top: 10px;
  align-items: center;
  min-height: 32px;
  width: 100%;
  justify-content: right;
  .page-mess {
    position: relative;
    margin-right: 50px;
    width: max-content;
    max-width: 405px;
    align-items: center;
    background: #172b4d;
    display: flex;
    border-radius: 14px;
    /* min-height: 39px; */
    padding: 7px 18px;
    order: 2;
    &.mess-image {
      background: transparent;
    }

    .v-avatar {
      position: absolute;
      right: -39px;
      top: 20px;
    }
  }
  .page-mess-new {
    position: relative;
    margin-right: 50px;
    width: max-content;
    max-width: 405px;
    align-items: center;
    background: #172b4d;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    // display: flex;
    border-radius: 14px;
    /* min-height: 39px; */
    padding: 12px;
    margin-bottom: 4px;
    order: 2;
    &.mess-image {
      background: transparent;
    }

    .v-avatar {
      position: absolute;
      right: -39px;
      top: 20px;
    }

  }
  .time-msg {
    color: #cccccc;
    order: 1;
    font-size: 11px;
    padding-right: 8px;
    text-align: end;
    align-self: flex-end;
  }
}

.user {
  display: flex;
  margin-top: 10px;
  align-items: center;
  min-height: 32px;
  width: 100%;
  justify-content: left;

  .user-mess {
    position: relative;
    margin-left: 50px;
    background: #ffffff;

    width: max-content;
    max-width: 405px;
    align-items: center;
    display: flex;
    border-radius: 14px;
    /* min-height: 39px; */
    padding: 7px 18px;
    &.mess-image {
      background: transparent;
    }
    .v-avatar {
      position: absolute;
      left: -48px;
      top: 20px;
    }
  }
  .user-mess-new {
    position: relative;
    margin-left: 50px;
    background: #ffffff;

    width: max-content;
    max-width: 405px;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    // display: flex;
    border-radius: 14px;
    margin-bottom: 4px;
    /* min-height: 39px; */
    padding: 12px;
    &.mess-image {
      background: transparent;
    }
    .v-avatar {
      position: absolute;
      left: -48px;
      top: 20px;
    }
  }
  .time-msg {
    color: #cccccc;
    font-size: 11px;
    align-self: flex-end;
    text-align: end;
  }
}

.box-msg {
  font-size: 13px;
  white-space: pre-line;
  word-break: break-word;
  .btn-copy-phone {
    position: relative;
    .v-btn {
    }
    .btn-copy-success {
      position: absolute;
    }
  }
}
</style>
