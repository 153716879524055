var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{class:_vm.active ? 'activeHeight' : '',attrs:{"id":"chat-cmt-main"},on:{"scroll":_vm.handleScroll}},[_c('div',_vm._l((_vm.user.messages),function(message,index){return _c('div',{key:index,class:message.message.is_echo ? 'page' : 'user',attrs:{"color":"rgba(0,0,0,0)","flat":"","id":_vm.user.messages.length > 20 && index == 20
          ? 'scrollMesCmt'
          : _vm.user.messages.length <= 20 && index == _vm.user.messages.length - 1
          ? 'scrollMesCmt'
          : ''}},[_c('div',{class:message.message.is_echo ? 'page-mess-new' : 'user-mess-new',style:({
          background:
            message.message.attachments.length > 0 ? 'transparent' : '',
          padding: message.message.attachments.length > 0 ? '0px 0px' : '',
        })},[(!message.message.is_echo)?_c('v-avatar',{staticClass:"mt-n5 ml-2",attrs:{"size":"30","elevation":"10"}},[_c('img',{attrs:{"alt":((_vm.user.name) + " avatar"),"src":_vm.user.customer.avatar_url || _vm.imgDefault}})]):_vm._e(),_c('div',{staticClass:"box-msg",style:({
            color: message.message.is_echo ? '#ffffff' : '#172B4D',
          })},[_vm._v(" "+_vm._s(message.message.text)+" "),_c('div',{staticClass:"statusMess"},[(
                message.message.is_echo &&
                message.message.statusMess == _vm.constant.SEND_MESS_PENDDING
              )?_c('v-progress-circular',{staticClass:"mt-1",attrs:{"size":15,"indeterminate":"","color":"primary"}}):_vm._e(),(
                message.message.is_echo &&
                message.message.statusMess == _vm.constant.SEND_MESS_ERROR
              )?_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-alert-circle-outline")]):_vm._e(),(
                message.message.is_echo &&
                message.message.statusMess == _vm.constant.SEND_MESS_SUCCESS
              )?_c('v-icon',{attrs:{"color":"blue","small":""}},[_vm._v("mdi-check-all")]):_vm._e()],1),(
              message.message.phone_number_detected && message.message.phone_number_detected !== 'false' &&
              !message.message.is_echo
            )?_c('div',{staticClass:"btn-copy-phone"},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"x-small":"","depressed":"","color":"#FD7C23","value":message.message.phone_number_detected},on:{"click":function($event){return _vm.copyPhone(message.message.phone_number_detected)}}},[_vm._v("Lưu SĐT ")])],1):_vm._e()]),(message.message.attachments.length > 0)?_c('div',{staticClass:"img-chat"},[_vm._l((message.message.attachments),function(img,index){return _c('div',{key:index},[(message.message.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}}):_vm._e(),(img.type == 'image')?_c('v-dialog',{attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":img.payload.url,"width":"100","height":"100"}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"img-dialog"},[_c('img',_vm._g(_vm._b({attrs:{"src":img.payload.url,"alt":""}},'img',_vm.attrs,false),_vm.on))])]):_vm._e()],1)}),_vm._l((message.message.attachments),function(video,index){return _c('div',{key:index},[(video.type === 'video')?_c('video',{attrs:{"width":"320","height":"176","controls":"controls"}},[_c('source',{attrs:{"src":video.payload.url,"type":"video/mp4"}})]):_vm._e()])}),_vm._l((message.message.attachments),function(audio,index){return _c('div',{key:index},[(audio.type === 'audio')?_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":audio.payload.url,"type":"audio/mpeg"}})]):_vm._e()])})],2):_vm._e(),(message.message.is_echo)?_c('v-avatar',{staticClass:"mt-n5 ml-2",attrs:{"size":"30","elevation":"10"}},[_c('img',{attrs:{"src":_vm.imgDefault}})]):_vm._e()],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }