import ApiService from './ApiService';

class UserShiftService extends ApiService {
    checkUserShiftApi(params) {
        return this.getArr(`/shift/check-user-shift`, params);
    }
    openShift(id) {
        return this.put(`/shift/open-shift/${id}`);
    }
    closingShift(id) {
        return this.put(`/shift/closing-shift/${id}`);
    }

}

const userShiftService = new UserShiftService;
export { userShiftService };
export default userShiftService;
