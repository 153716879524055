<template>
  <div
    id="footer-send-mesbox"
    app
    color="transparent"
    inset
    style="display: block"
  >
    <div
      class="img-footer px-2"
      flat
      color="#F6F6F6"
      style="display: flex"
      v-show="listImage.length > 0"
    >
      <div class="img-item-footer" v-for="(i, index) in listImage" :key="index">
        <v-img width="50" height="50" class="rounded-lg" :src="i"> </v-img>
        <v-icon @click="deleteImg(index)" x-small color="black"
          >mdi-close-circle</v-icon
        >
      </div>
    </div>
    <form action="" @submit.prevent="sendMessage" class="d-flex py-2">
      <div flat color="#F6F6F6" class="pl-3">
        <v-list-item class="px-0">
          <v-list-item-subtitle v-show="successMsg" style="color: #219653"
            >Đã gửi tin nhắn thành công</v-list-item-subtitle
          >
          <v-list-item-subtitle v-show="!successMsg"></v-list-item-subtitle>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" width="600" @click:outside="closeDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon v-show="showLoadImage">
                <v-icon @click="getImages"> mdi-image </v-icon></v-btn
              >
            </template>
            <v-card class="library-list-images">
              <div class="library-title">
                <h3>Gửi ảnh</h3>
                <v-icon @click="closeDialog" large>mdi-close</v-icon>
              </div>
              <v-divider></v-divider>
              <div class="library-images">
                <label
                  v-for="(i, index) in images"
                  :key="index"
                  :for="`b+${i.id}`"
                >
                  <v-img
                    :src="i.url"
                    style="border: 1px solid #ddd"
                    width="90"
                    height="90"
                    class="ma-2"
                  >
                    <input
                      class="ml-1"
                      type="checkbox"
                      v-model="listImage"
                      :value="i.url"
                      name=""
                      :id="`b+${i.id}`" /></v-img
                ></label>
              </div>
              <div class="text-center mb-2" v-show="showLoadMore">
                <v-btn @click="loadMoreImage" color="blue" class="white--text"
                  >Load thêm ảnh</v-btn
                >
              </div>
              <div class="library-noti-send">
                <span
                  ><v-icon color="#F2994A">mdi-lightbulb-on</v-icon> ALT + Enter
                  sau khi chọn ảnh để gửi trả lời nhanh</span
                >
              </div>
              <v-divider></v-divider>
              <div class="library-send">
                <div class="library-send-new">
                  <v-file-input
                    v-show="listImage.length < 9"
                    @change="uploadImage"
                    v-model="image"
                    prepend-inner-icon="mdi-cloud-upload"
                    prepend-icon=""
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Tải lên ảnh mới"
                    outlined
                    dense
                  >
                  </v-file-input>
                </div>

                <div class="library-choosed">
                  <v-btn
                    @click="chooseImages"
                    class="white--text"
                    color="#289A4C"
                    depressed
                  >
                    <v-icon class="mr-1">mdi-checkbox-marked-circle</v-icon>
                    Chọn xong
                  </v-btn>
                  <v-snackbar v-model="snackbar" :timeout="3000">
                    Chưa có ảnh nào được chọn!

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </div>
              </div>
            </v-card>
          </v-dialog>
          <v-icon class="mx-1">mdi-comment-text-outline</v-icon>
        </v-list-item>
      </div>
      <div flat color="#F6F6F6" class="px-1 w-full custom-box-chat">
        <v-textarea
          label="Nhập nội dung tin nhắn"
          auto-grow
          rows="1"
          autofocus
          row-height="40"
          flat
          outlined
          dense
          solo
          v-model="msg"
          @keydown="inputHandler"
          ref="txtMess"
        ></v-textarea>
      </div>
      <div flat color="#F6F6F6" class="pr-3">
        <v-list-item class="px-0">
          <v-btn icon type="submit" :disabled="disabledMes">
            <v-icon class="ml-2" :color="(msg || listImage.length) ? '#009FE0' : 'gray'">mdi-send</v-icon>
          </v-btn>
        </v-list-item>
      </div>
    </form>
  </div>
</template>

<script>
import _ from "lodash";
import fb from "../../plugins/fb-app";
import eventBus from "../../plugins/eventBus";
import { chatService } from "../../repositories/ChatService";
import { constant } from "../../helpers/constant";
export default {
  props: {
    user: {
      type: [Array, Object],
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    covLabels: {
      type: Array,
      default: () => [],
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
    currentComment: {
      type: Object,
      default: () => {},
    },
  },
  name: "dialog-footer",
  data: () => ({
    successMsg: false,
    disabledMes: false,
    image: null,
    msg: "",
    data: [
      {
        img: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      },
    ],
    labelChoose: [],
    listImage: [],
    constant,
    doubleSendMsg: false,
    images: [],
    dialog: false,
    snackbar: false,
    page: 1,
    showLoadMore: true,
    showLoadImage: true,
  }),
  watch: {
    image: {
      handler: (val) => {
        eventBus.$emit("file-img", val);
      },
      deep: true,
    },
    user: {
      handler: function (val) {
        if (val.messages && val.messages.length > 0) {
          this.showLoadImage = true;
        } else {
          this.showLoadImage = false;
        }
        this.msg = "";
        this.listImage = [];
      },
      deep: true,
    },
  },

  methods: {
    focusInput() {
      setTimeout(() => {
        this.$refs.txtMess.focus();
      }, 100);
    },
    inputHandler(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        this.sendMessage();
        e.preventDefault();
      }
    },
    async sendMessage() {
      const images = this.listImage;
      if (this.doubleSendMsg) {
        return;
      }
      this.doubleSendMsg = true;
      if (this.msg.trim() !== "") {
        this.disabledMes = true;
        const params = {
          recipientId: this.user.psid ? this.user.psid : this.currentUser.psid,
          accessToken: this.user.psid
            ? this.user.page.page_token
            : this.currentUser.page.page_token,
          content: this.msg,
        };
        let msg = this.msg;
        this.$emit("getMessage", msg, constant.SEND_MESS_PENDDING);
        try {
          let res = await fb.sendMessage(params);
          this.doubleSendMsg = false;
          if (res) {
            this.$emit("getMessage", msg, constant.SEND_MESS_SUCCESS);
            this.msg = "";
            this.successMsg = true;
            this.disabledMes = false;
            setTimeout(() => {
              this.successMsg = false;
            }, 1000);
            eventBus.$emit("scrollEnd");
          }
        } catch (error) {
          // Maybe need to check comment can send private message
          const params = {
            comment_id: this.currentComment.comment_id
              ? this.currentComment.comment_id
              : this.currentComment.comment.comment_id,
            message: msg,
            access_token: this.currentUser.page.page_token,
            page_id: this.currentUser.page_id,
          };
          fb.sendPrivateMessage(params)
            .then(() => {
              this.user.messages = this.user.messages ? this.user.messages : [];
              this.$emit("getMessage", msg, constant.SEND_MESS_SUCCESS);
              this.msg = "";
              this.successMsg = true;
              this.disabledMes = false;
              setTimeout(() => {
                this.successMsg = false;
              }, 1000);
              eventBus.$emit("scrollEnd");
            })
            .catch(() => {
              this.$emit("getMessage", msg, constant.SEND_MESS_ERROR);
              this.msg = "";
              this.doubleSendMsg = false;
              this.successMsg = false;
              this.disabledMes = false;
              this.$swal.fire({
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
                icon: "error",
                title:
                  error.error_subcode == constant.FACEBOOK_24H
                    ? "Không gửi được tin nhắn do quá 24h khách hàng chưa nhắn tin cho bạn!"
                    : error.error_subcode == constant.FACEBOOK_REPPLY
                    ? "Vui lòng chờ khách hàng phản hồi!"
                    : error.error_subcode == constant.INTERNET ? 'Không có mạng!'
                    : error.message,
              });
            });
        }
      } else {
        this.doubleSendMsg = false;
        this.msg = "";
      }

      if (images.length > 0) {
        this.disabledMes = true;
        images.forEach(async (image) => {
          const params = {
            comment_id: this.currentComment.comment_id
              ? this.currentComment.comment_id
              : this.currentComment.comment.comment_id,
            fileUrl: image,
            type: "image",
            recipientId: this.user.psid
              ? this.user.psid
              : this.currentUser.psid,
            accessToken: this.user.psid
              ? this.user.page.page_token
              : this.currentUser.page.page_token,
          };
          this.$emit("getImage", image, constant.SEND_MESS_PENDDING);
          await fb
            .sendFile(params)
            .then(() => {
              this.$emit("getImage", image, constant.SEND_MESS_SUCCESS);
              this.listImage = [];
              this.disabledMes = false;
              this.successMsg = true;
              setTimeout(() => {
                this.successMsg = false;
              }, 1000);
            })
            .catch(() => {
              fb.sendPrivateFile(params)
                .then(() => {
                  this.$emit("getImage", image, constant.SEND_MESS_SUCCESS);
                  this.listImage = [];
                  this.disabledMes = false;
                  this.successMsg = true;
                  setTimeout(() => {
                    this.successMsg = false;
                  }, 1000);
                })
                .catch((error) => {
                  this.$emit("getImage", image, constant.SEND_MESS_ERROR);
                  this.listImage = [];
                  this.disabledMes = false;
                  this.$swal.fire({
                    icon: "error",
                    title:
                      error.error_subcode == constant.FACEBOOK_24H
                        ? "Không gửi được tin nhắn do quá 24h khách hàng chưa nhắn tin cho bạn!"
                        : error.error_subcode == constant.FACEBOOK_REPPLY
                        ? "Vui lòng chờ khách hàng phản hồi!"
                        : error.error_subcode == constant.INTERNET ? 'Không có mạng!'
                        : error.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                });
            });
        });
      }
      this.doubleSendMsg = false;
    },
    deleteImg(index) {
      this.listImage.splice(index, 1);
    },
    async uploadImage() {
      if (this.image) {
        let formData = new FormData();
        formData.append("image", this.image);
        const uploadResponse = await chatService.uploadImage(formData);
        if (_.get(uploadResponse, "success", true)) {
          const newImage = _.get(uploadResponse, "data", {});
          this.listImage.push(newImage.img_url);
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Tải ảnh không thành công",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.image = null;
      }
      this.getImages();
    },
    async getImages() {
      this.showLoadMore = true;
      this.page = 1;
      let params = {
        page: this.page,
        type: 1,
      };
      const res = await chatService.getImages(params);
      this.images = res.data;
    },
    chooseImages() {
      if (this.listImage.length > 0) {
        this.dialog = false;
        this.focusInput();
      } else {
        this.snackbar = true;
      }
    },
    async loadMoreImage() {
      this.page = this.page + 1;
      let params = {
        page: this.page,
        type: 1,
      };
      const res = await chatService.getImages(params);
      if (res.data.length == 0) {
        this.showLoadMore = false;
      }
      this.images = this.images.concat(res.data);
    },
    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.focusInput();
      });
    },
  },
};
</script>
<style lang="scss">
#footer-send-mesbox {
  background: #f6f6f6 !important;
  .v-text-field {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
  padding: 0px !important;
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 8px;
    display: none;
  }
  textarea {
    max-height: 60px;
    overflow: auto;
  }
  .label-footer {
    overflow: auto;
    padding: 0px 12px;
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
      height: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    ul {
      list-style: none;
      display: -webkit-box;
      padding-left: 0px !important;
      li {
        font-size: 11px;
        text-align: center;
        color: white;
        cursor: pointer;
        .label-title {
          height: 20px;
          width: 95px;
          opacity: 0.3;
          text-transform: uppercase;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-top: 4px;
        }

        input:checked + label {
          .label-title {
            height: 20px;
            width: 95px;
            opacity: 1 !important;
          }
        }
      }
    }
  }
  .custom-box-chat {
    .v-input__slot {
      border-radius: 12px;
    }
    fieldset {
      border-radius: 12px;
    }
  }
  .img-footer {
    padding-bottom: 2px;
    overflow: auto;
    margin-bottom: 4px;
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
      height: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .img-item-footer {
      position: relative;
      margin: 15px 0px 0px 6px;
      .v-icon {
        position: absolute;
        right: -6px;
        top: -5px;
        cursor: pointer;
      }
    }
  }
}
.library-list-images {
  .library-title {
    width: 100%;
    text-align: center;
    align-items: center;
    height: 60px;
    padding-top: 19px;
    text-transform: uppercase;
    color: #172b4d;
    .v-icon {
      position: absolute;
      top: 13px;
      right: 12px;
    }
  }
  .library-images {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 24px 0px 19px 0px;
    .v-img {
      border: 1px solid #ddd;
    }
  }
  .library-noti-send {
    font-size: 14px;
    margin: 0px 0px 20px 32px;
    color: #f2994a;
  }
  .library-send {
    height: 78px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 37px;
  }
}
</style>
