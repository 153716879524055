<template>
  <v-sheet
    @scroll="handleScroll"
    id="cmt-main"
    :class="active ? 'activeHeight' : ''"
    class="box-comment"
  >
    <!--MESSAGE-DIALOG-->
    <v-dialog
      v-model="msgDialog"
      persistent
      max-width="800px"
      max-height="100% !important"
    >
      <v-card>
        <v-card-title class="text-center title-box-message">
          <span class="text-h5">
            <v-icon class="mx-1" color="#3A559F">mdi-facebook-messenger</v-icon
            >Tin nhắn mới đến
            <span class="font-weight-bold">{{
              sendToName ? sendToName : user.customer.name
            }}</span>
          </span>
          <v-btn icon class="float-right" @click="msgDialog = false">
            <v-icon class="mx-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="comment-dialog-messages">
            <DialogMessages v-if="userMessage" :user="userMessage" />
          </div>
          <div class="action-comment-box-messages">
            <SendMessageBox
              @getMessage="getMessage"
              @getImage="getImage"
              :currentUser="user"
              :user="userMessage"
              :currentComment="currentComment"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--END-DIALOG-->
    <div class="post-wrapper">
      <div class="post">
        {{ user.post.message }}
      </div>
      <div class="post-image" v-if="user.post.attachments.length">
        <div
          class="mr-1"
          v-for="(img, index) in user.post.attachments"
          :key="index"
        >
          <v-dialog width="auto" v-if="img.type == 'photo'">
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                :src="img.payload.url"
                width="100"
                height="100"
              ></v-img>
            </template>

            <div class="img-dialog">
                  <img :src="img.payload.url" alt="" />
                </div>
          </v-dialog>
          <video
            v-if="img.type == 'video_inline'"
            :src="img.payload.url"
            controls
          />
        </div>
      </div>
      <div class="go-to-permalink float-right">
        <a :href="'https://facebook.com/' + user.post.id" target="_blank"
          >Xem bài gốc</a
        >
      </div>
      <div class="clearfix"></div>
    </div>
    <div>
      <div
        v-for="(comment, index) in user.comments"
        :key="index"
        color="rgba(0,0,0,0)"
        flat
        :class="comment.comment.is_echo ? 'page' : 'user'"
      >
        <div
          :style="{
            background:
              comment.comment.attachments.length > 0 ? 'transparent' : '',
            padding: comment.comment.attachments.length > 0 ? '0px 0px' : '',
          }"
          :class="comment.comment.is_echo ? 'page-mess-new' : 'user-mess-new'"
        >
          <v-avatar
            v-if="!comment.comment.is_echo"
            class="mt-n5 ml-2"
            size="30"
            elevation="10"
          >
            <img
              :alt="`${user.name} avatar`"
              :src="user.customer.avatar_url || imgDefault"
            />
          </v-avatar>
          <div class="name-time d-flex">
            <div class="name-cmt">
              {{ user && user.customer ? user.customer.name : "Ẩn danh" }}
            </div>
            <div class="time-cmt my-auto">{{ formatDateCustom(comment.comment.ts) }}</div>
          </div>
          <div
            style="font-size: 15px; white-space: pre-line"
            :style="{
              color: comment.comment.is_echo ? '#ffffff' : '#172B4D',
            }"
          >
            {{ comment.comment.message }}
            <div
              class="btn-copy-phone"
              v-if="checkValidatePhone(comment.comment.message).btnPhone"
            >
              <v-btn
                x-small
                style="color: white"
                depressed
                color="#FD7C23"
                @click="
                  copyPhone(
                    checkValidatePhone(comment.comment.message).btnPhone[
                      checkValidatePhone(comment.comment.message).btnPhone
                        .length - 1
                    ]
                  )
                "
                :value="
                  checkValidatePhone(comment.comment.message).btnPhone[
                    checkValidatePhone(comment.comment.message).btnPhone
                      .length - 1
                  ]
                "
                >Lưu SĐT
              </v-btn>
            </div>
          </div>
          <div class="img-chat" v-if="comment.comment.attachments.length > 0">
            <div
              class="mr-1"
              v-for="(img, indexImg) in comment.comment.attachments"
              :key="indexImg"
            >
              <v-dialog
                width="auto"
                v-if="
                  img.type == 'image' || img.type == '' || img.type == 'photo'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    class="comment-photo"
                    v-bind="attrs"
                    v-on="on"
                    :src="img.payload.url"
                    width="100"
                    height="100"
                  ></v-img>
                </template>
                 <div class="img-dialog">
                  <img :src="img.payload.url" alt="" />
                </div>
              </v-dialog>

              <video
                v-if="img.type == 'video'"
                :src="img.payload.url"
                controls
                class="comment-video"
              />
            </div>
          </div>
          <div v-if="!comment.comment.is_echo">
            <LikeBtn :user="user" :comment="comment" />
            <HideBtn :user="user" :comment="comment" />
            <ReplyBtn :user="user" :comment="comment" />
            <v-btn
              icon
              :href="'https://facebook.com/' + comment.comment.comment_id"
              target="_blank"
            >
              <v-icon small class="mx-1" color="#3A559F">mdi-facebook</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon small class="mx-1" color="#868686"
                >mdi-trash-can-outline</v-icon
              >
            </v-btn>
            <v-btn icon @click="openMessageBoxByComment(comment)">
              <v-icon small class="mx-1" color="blue"
                >mdi-facebook-messenger</v-icon
              >
            </v-btn>
          </div>
        </div>
        <!-- Replies comments -->
        <div
          v-for="(item2, index2) in repliesComments(index)"
          :key="index2"
          color="rgba(0,0,0,0)"
          flat
          class="user-reply"
        >
          <div
            v-if="item2.attachments"
            class="user-mess-new"
            :style="{ background: item2.is_echo ? 'black' : 'white' }"
          >
            <v-avatar
              v-if="item2.is_echo"
              class="mt-n5 ml-2"
              size="30"
              elevation="10"
            >
              <img
                :alt="`${user.name} avatar`"
                :src="user.page.avatar || imgDefault"
              />
            </v-avatar>
            <v-avatar
              v-if="!item2.is_echo"
              class="mt-n5 ml-2"
              size="30"
              elevation="10"
            >
              <img
                :alt="`${user.name} avatar`"
                :src="
                  user.customer.avatar_url != null
                    ? user.customer.avatar_url
                    : imgDefault
                "
              />
            </v-avatar>
            <v-avatar
              v-if="item2.sender"
              class="mt-n5 ml-2"
              size="30"
              elevation="10"
            >
              <img
                :alt="`${item2.sender.name} avatar`"
                :src="
                  item2.sender.avatar_url != null
                    ? item2.sender.avatar_url
                    : imgDefault
                "
              />
            </v-avatar>
            <div
              style="font-size: 15px; white-space: pre-line"
              :style="{ color: item2.is_echo ? 'white' : 'black' }"
            >
              <div class="name-time d-flex">
                <div class="name-cmt">
                  {{ item2.sender ? item2.sender.name : "" }}
                </div>
                <div class="time-cmt my-auto">{{ formatDateCustom(item2.ts) }}</div>
              </div>
              {{ item2.message }}

              <div class="statusMessCmt">
                <v-progress-circular
                  v-show="
                    item2.statusMess &&
                    item2.statusMess == constant.SEND_MESS_PENDDING
                  "
                  class="mt-1"
                  :size="15"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-icon
                  color="red"
                  small
                  v-show="
                    item2.statusMess &&
                    item2.statusMess == constant.SEND_MESS_ERROR
                  "
                  >mdi-alert-circle-outline</v-icon
                >
                <v-icon
                  color="blue"
                  small
                  v-show="
                    item2.statusMess &&
                    item2.statusMess == constant.SEND_MESS_SUCCESS
                  "
                  >mdi-check-all</v-icon
                >
              </div>
              <div
                class="btn-copy-phone"
                v-if="checkValidatePhone(item2.message).btnPhone"
              >
                <v-btn
                  x-small
                  style="color: white"
                  depressed
                  color="#FD7C23"
                  @click="
                    copyPhone(
                      checkValidatePhone(item2.message).btnPhone[
                        checkValidatePhone(item2.message).btnPhone.length - 1
                      ]
                    )
                  "
                  :value="
                    checkValidatePhone(item2.message).btnPhone[
                      checkValidatePhone(item2.message).btnPhone.length - 1
                    ]
                  "
                  >Lưu SĐT
                </v-btn>
              </div>
            </div>
            <div class="img-chat" v-if="item2.attachments.length > 0">
              <div
                class="mr-1"
                v-for="(img, index3) in item2.attachments"
                :key="index3"
              >
                <v-dialog
                  width="auto"
                  v-if="
                    img.type == 'image' || img.type == '' || img.type == 'photo'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      class="comment-photo"
                      v-bind="attrs"
                      v-on="on"
                      :src="img.payload.url"
                      width="100"
                      height="100"
                    ></v-img>
                  </template>

                 <div class="img-dialog">
                  <img :src="img.payload.url" alt="" />
                </div>
                </v-dialog>

                <video
                  v-if="img.type == 'video'"
                  :src="img.payload.url"
                  controls
                  class="comment-video"
                />
              </div>
            </div>
            <div v-if="!item2.is_echo">
              <LikeBtnReply :user="user" :comment="item2" />
              <HideBtnReply :user="user" :comment="item2" />
              <ReplyBtnReply :user="user" :comment="item2" />
              <v-btn
                icon
                :href="'https://facebook.com/' + item2.comment_id"
                target="_blank"
              >
                <v-icon small class="mx-1" color="#3A559F">mdi-facebook</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon small class="mx-1" color="#868686"
                  >mdi-trash-can-outline</v-icon
                >
              </v-btn>
              <v-btn icon @click="openMessageBoxByComment(item2, item2.sender)">
                <v-icon small class="mx-1" color="blue"
                  >mdi-facebook-messenger</v-icon
                >
              </v-btn>
            </div>
          </div>
        </div>
        <!-- End Replies comment -->
      </div>
    </div>
    <div id="scroll"></div>
  </v-sheet>
</template>

<script>
import eventBus from "../../plugins/eventBus";
import moment from "moment";
import { chatService } from "../../repositories/ChatService";
import DialogMessages from "./DialogMessages.vue";
import SendMessageBox from "./SendMessageBox.vue";
import LikeBtn from "./LikeBtn.vue";
import HideBtn from "./HideBtn.vue";
import LikeBtnReply from "./LikeBtnReply.vue";
import HideBtnReply from "./HideBtnReply.vue";
import ReplyBtn from "./ReplyBtn.vue";
import ReplyBtnReply from "./ReplyBtnReply.vue";
import { constant } from "../../helpers/constant";

export default {
  name: "box-comments",
  data: () => ({
    scroll: null,
    active: false,
    imgDefault: "https://cdn-cf.vuihoc.vn/default/default_avatar.jpg",
    post: null,
    msgDialog: false,
    userMessage: null,
    labels: [],
    covLabels: [],
    constant,
    sendToName: null,
    ts: null,
    disableScrollTop: true,
    currentComment: true,
  }),
  components: {
    DialogMessages,
    SendMessageBox,
    LikeBtn,
    HideBtn,
    LikeBtnReply,
    HideBtnReply,
    ReplyBtn,
    ReplyBtnReply,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  sockets: {
    update_conversation: function (data) {
      if (this.user.psid === data.psid && this.msgDialog && !data.replied) {
        this.getMessages(data);
      }
    },
  },
  created() {
    // eventBus.$on("file-img", (val) => {
    //   if (val == null) {
    //     this.active = false;
    //   } else {
    //     this.active = true;
    //   }
    // });
    // this.getPost();

    eventBus.$on("scrollEndCMT", () => {
      this.scrollToEnd();
    });
    eventBus.$on("scrollTopMesCmt", () => {
      this.disableScrollTop = false;
      this.ts = this.userMessage.messages[0].message.ts;
      const params = {
        p_date: this.formatDate(this.userMessage.messages[0].message.ts),
        limit: 20,
        psid: this.currentComment.sender
          ? this.currentComment.sender.psid
          : this.user.psid,
      };
      this.sendToName = this.currentComment.sender
        ? this.currentComment.sender.name
        : this.user.customer.name;
      if (this.disableScrollTop) {
        return;
      }
      chatService
        .getConvMessage(this.user._id, params)
        .then((res) => {
          this.disableScrollTop = true;
          res.data.messages.reverse();
          if (this.userMessage) {
            let messages = res.data.messages.concat(this.userMessage.messages);
            if (this.ts == messages[0].message.ts) {
              eventBus.$emit("stopScrollTopMes");
            }
            this.userMessage.messages = messages;
          } else {
            this.userMessage = res.data;
          }
        })
        .catch((err) => {
          this.disableScrollTop = true;
          this.$swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
      eventBus.$emit("scrollEndMes");
    });
  },
  methods: {
    repliesComments(index) {
      return this.user.comments[index].replies;
    },
    handleScroll(event) {
      if (event.target.scrollTop === 0) {
        this.$emit("scrollTop");
      }
    },
    scrollToEnd: function () {
      if (document.getElementById("scroll")) {
        document.getElementById("scroll").scrollIntoView();
      }
    },

    compare(a, b) {
      if (moment(a.ts).isAfter(b.ts)) {
        return 1;
      }

      return -1;
    },
    openMessageBoxByComment(comment, sender = null) {
      this.currentComment = comment;
      this.msgDialog = true;
      if (sender) {
        return this.getMessages(sender);
      }
      this.getMessages();
    },
    getMessages(sender = null) {
      eventBus.$emit("openDialogMes");
      const params = {
        // p_date: this.formatDate(this.user.messages[0].message.ts),
        limit: 20,
        psid: sender ? sender.psid : this.user.psid,
      };
      this.sendToName = sender ? sender.name : this.user.customer.name;

      chatService
        .getConvMessage(this.user._id, params)
        .then((res) => {
          this.userMessage = res.data;
          this.userMessage.messages.reverse();
          eventBus.$emit("scrollEnd");
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "Người dùng chưa từng nhắn tin đến fanpage!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    copyPhone(phoneMsg) {
      navigator.clipboard.writeText(phoneMsg);
    },
    getMessage(msg, status) {
      if (status == constant.SEND_MESS_PENDDING) {
        let appendMsg = {
          message: {
            is_echo: true,
            statusMess: status,
            text: msg,
            attachments: [],
          },
        };
        if (this.userMessage.messages) {
          this.userMessage.messages.push(appendMsg);
        } else {
          this.userMessage = {
            messages: [appendMsg]
          }
        }
      } else {
        const index = this.userMessage.messages.findIndex(
          (s) =>
            s.message.statusMess == constant.SEND_MESS_PENDDING &&
            s.message.text == msg
        );
        if (index > -1) {
          this.userMessage.messages[index].message.statusMess = status;
        }
      }
    },
    getImage(image, status) {
      if (status == constant.SEND_MESS_PENDDING) {
        let appendMsg = {
          message: {
            attachments: [
              {
                payload: { url: image },
                type: "image",
              },
            ],
            is_echo: true,
            statusMess: status,
          },
        };
        if (this.userMessage.messages) {
          this.userMessage.messages.push(appendMsg);
        } else {
          this.userMessage.messages = [appendMsg];
        }
      } else {
        const index = this.userMessage.messages.findIndex(
          (s) =>
            s.message.statusMess == constant.SEND_MESS_PENDDING &&
            s.message.attachments[0].payload.url == image
        );
        if (index > -1) {
          this.userMessage.messages[index].message.statusMess = status;
        }
      }
    },
  },
  mounted() {
    this.scrollToEnd();
  },
  beforeDestroy() {
    eventBus.$off("file-img");
    eventBus.$off("scrollEndCMT");
  },
};
</script>

<style lang="scss" scoped>
#main-box {
  background: #fff8f3;
  .v-list-item--three-line {
    min-height: 0px !important;
  }
  .v-sheet:not(.v-sheet--outlined) {
    box-shadow: none !important;
  }
  .v-text-field__details {
    display: none;
  }
  .v-progress-circular {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  #cmt-main {
    &.box-comment {
      background: #EEF0F1 !important;
    }
    overflow-y: auto;
    max-height: calc(100vh - 310px);

    &.activeHeight {
      max-height: calc(100vh - 388px);
    }
    //  max-height: calc(100vh);
    background: #fff8f3;
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: 0px 4px 4px 0px #00000029 inset;
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a0a0a0;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #bebebe;
    }
    .statusMessCmt {
      position: absolute;
      right: -20px;
      top: 4px;
    }
  }
  .img-chat {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    .comment-photo {
      max-width: 200px;
    }
    .comment-video {
      max-width: 200px;
    }
  }
}
.title-box-message {
  display: block;
}

.post-wrapper {
  border-bottom: 1px solid #ccc;
  .go-to-permalink {
    font-size: 10px;
    a,
    a:link,
    a:visited {
      color: #666;
    }
  }
  .clearfix {
    clear: both;
  }
}

.post {
  padding: 20px;
  font-size: 15px;
  white-space: pre-line;
  color: #172b4d;
}
.post-image {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}
#main-box {
  .v-list-item--three-line {
    min-height: 0px !important;
  }
  .v-sheet:not(.v-sheet--outlined) {
    box-shadow: none !important;
  }
  .v-text-field__details {
    display: none;
  }
  .v-progress-circular {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  #chat-main {
    overflow-y: auto;
    max-height: calc(100vh - 310px);

    &.activeHeight {
      max-height: calc(100vh - 388px);
    }
    //  max-height: calc(100vh);
    background: #fff8f3;
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: 0px 4px 4px 0px #00000029 inset;
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #a0a0a0;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #bebebe;
    }
  }
  .img-chat {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
  }
}
.before-select {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  b {
    border: 1px solid #efecec;
    border-radius: 20px;
    padding: 3px 14px;
    color: #545353;
    background: #fbd8d8;
  }
}

.page {
  display: flex;
  margin-top: 10px;
  align-items: center;
  min-height: 32px;
  width: 100%;
  justify-content: right;
  .page-mess {
    position: relative;
    margin-right: 50px;
    width: max-content;
    max-width: 405px;
    align-items: center;
    background: #172b4d;
    display: flex;
    border-radius: 14px;
    /* min-height: 39px; */
    padding: 7px 18px;
    &.mess-image {
      background: transparent;
    }

    .v-avatar {
      position: absolute;
      right: -39px;
      top: 20px;
    }
  }
  .page-mess-new {
    position: relative;
    margin-right: 50px;
    width: max-content;
    max-width: 405px;
    align-items: center;
    background: #172b4d;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    // display: flex;
    border-radius: 14px;
    /* min-height: 39px; */
    padding: 12px;
    margin-bottom: 4px;
    order: 2;
    &.mess-image {
      background: transparent;
    }

    .v-avatar {
      position: absolute;
      right: -39px;
      top: 20px;
    }

  }
}

.user {
  display: block;
  margin-top: 10px;
  align-items: center;
  min-height: 32px;
  width: 100%;
  justify-content: left;

  .user-mess {
    position: relative;
    margin-left: 50px;
    background: #ffffff;

    width: max-content;
    max-width: 405px;
    align-items: center;
    display: block;
    border-radius: 14px;
    /* min-height: 39px; */
    padding: 7px 18px;
    &.mess-image {
      background: transparent;
    }
    .v-avatar {
      position: absolute;
      left: -48px;
      top: 20px;
    }
  }
  .user-mess-new {
    position: relative;
    margin-left: 50px;
    background: #ffffff;

    width: max-content;
    max-width: 405px;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    // display: flex;
    border-radius: 14px;
    margin-bottom: 4px;
    /* min-height: 39px; */
    padding: 12px;
    &.mess-image {
      background: transparent;
    }
    .v-avatar {
      position: absolute;
      left: -48px;
      top: 20px;
    }
  }
}

.user-reply {
  display: flex;
  margin-top: 10px;
  align-items: center;
  min-height: 32px;
  width: 100%;
  justify-content: left;
  padding-left: 20px;
}
.name-time {
  align-self: flex-end;
  justify-content: space-between;
  color: #a5adbb;
  .name-cmt {
    font-weight: 700;
    font-size: 13px;
  }
  .time-cmt {
    order: 2;
    font-size: 11px;
  }
}
.time-cmt-user {
  font-size: 11px;
  color: #a5adbb;
}
.btn-copy-phone {
  position: relative;
  .v-btn {
  }
  .btn-copy-success {
    position: absolute;
  }
}
</style>
